import { WagmiConfig, createClient, createStorage, Chain, configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { bsc, bscTestnet } from 'wagmi/chains'

const configuration = configureChains(
    [bsc, bscTestnet],
    [
      jsonRpcProvider({
        rpc: (chain) => {
          switch (chain.id) {
            case 56:
              return { http: "https://bsc.getblock.io/9227e3f9-31b8-4f7c-91bf-f43ad4cf816a/mainnet/" }
            case 97:
              return { http: "https://data-seed-prebsc-2-s3.binance.org:8545/" }
            default:
              return { http: "https://data-seed-prebsc-2-s3.binance.org:8545/" }
          }
        },
      }),
    ],
  )

export const chains = configuration.chains
export const provider = configuration.provider