import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PiggyCard from '../../shared/piggy-card'

import './styles.scss'
import { useContract, useContractRead, useSigner } from 'wagmi'
import { useNFTCollection } from '../../../services/app/nftCollection'
import { NFT } from '../../../services/app/interfaces'
import { BigNumber, ethers } from 'ethers'
import { gameABI, legendaryDividendABI, NameRegistryABI, nftABI } from '../../../services/app/volumewars'
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'
import { formatEther } from 'ethers/lib/utils'
import { getLegendaryIngredients, stringToBytes32 } from '../../../services/app/utils'
const { REACT_APP_NETWORK } = process.env

const Profile = ({address, name}: {address: string | undefined, name: string | undefined}) => {

  const signer = useSigner()

  const gameContract: ethers.Contract = useContract({
    ...gameABI,  signerOrProvider: signer.data
  })!

  const experienceRaw = useContractRead({
    ...gameABI,
    functionName: 'totalExperienceOf',
    args: [address] 
  })

  const experience: string = useMemo(() => {
    if (!experienceRaw.data) {
      return "0"
    }
    return parseFloat(formatEther(experienceRaw.data as any as BigNumber)).toFixed(3).toString()
  }, [experienceRaw.status])

  const nftBalanceRaw = useContractRead({
    ...nftABI,
    functionName: 'balanceOf',
    args: [address] 
  })
  const nftBalance: number = useMemo(() => {
    if (!nftBalanceRaw.data) {
      return 0
    }
    return (nftBalanceRaw.data as any as BigNumber).toNumber()
  }, [nftBalanceRaw.status])

  const [nftCollection, refetchNFTCollection] = useNFTCollection(address)
  const [stakedLegendaryCollection, setStakedLegendaryCollection] = useState<NFT[]>([])

  const stakedNFTCollection = useContractRead({
    ...legendaryDividendABI,
    functionName: 'stakedNFTCollectionOf',
      args: [address]
  })

  const withdrawableDividendData = useContractRead({
    ...legendaryDividendABI,
    functionName: 'withdrawableDividendOf',
      args: [address]
  })

  const withdrawableDividend = useMemo(() => {
    if (!BigNumber.isBigNumber(withdrawableDividendData.data)){
      return 0
    }
    const withdrawable = withdrawableDividendData.data as any as BigNumber
    return parseFloat(formatEther(withdrawable))

  }, [withdrawableDividendData.data])

  useEffect(() => {
    if (!stakedNFTCollection.data) {
      return
    }
    const stakedArray: BigNumber[][] = stakedNFTCollection.data as BigNumber[][]
    let ordered: NFT[] = []
    for (var nftarray of stakedArray) {
      const item: NFT = {
        id: nftarray[0].toNumber(),
        set: nftarray[1].toNumber(),
        num: nftarray[2].toNumber()
      }
      ordered.push(item)
    }
    setStakedLegendaryCollection(ordered)

  }, [stakedNFTCollection.status])

  useEffect(() => {
    refetchNFTCollection({args: [address]})
    stakedNFTCollection.refetch()
    withdrawableDividendData.refetch()
  }, [address])

  async function forgeLegendary(seasonCards: {[num: number]: NFT[]}) {
    const ingredients = getLegendaryIngredients(seasonCards)
    if (!ingredients) {
      alert("You need a season's full set to forge a legendary")
    } else {
      if (!await checkChainId(gameContract)) {
        return
      }
      if (await waitTxWithToast(gameContract.forgeLegendary(ingredients.map((ingredient) => ingredient.id)))) {
        refetchNFTCollection({args: [address]})
      }
    }
  }

  const nftSeasons = useMemo(() =>
    Object.keys(nftCollection)
    .filter((v: string) => (v != 'legendary'))
    .map((seasonStr: string) => parseInt(seasonStr)),
    [nftCollection])

  return (
    <div className='page locker-page'>
      <div className='page-content'>
        <div className='page-title'>{name || "NFT Locker"}</div>
        <div className='page-subtitle'>NFT Locker for {address}</div>

        <div className="season-list table-wrapper three-column">
          <div className="table-title">Stats</div>
          <div className="table card">
          <div className="table-header card-content">
          <div className="table-cell">Legendary NFTs</div>
          <div className="table-cell">Total NFTs</div>
          <div className="table-cell">Total Volume</div>

          </div>
            <div className="table-row card-content">
            <div className="table-cell">{nftCollection.legendary.length + stakedLegendaryCollection.length}</div>
            <div className="table-cell">{nftBalance}</div>
            <div className="table-cell">{experience} BNB</div>

            </div>
          </div>
        </div>
        
        <div className='locker-blocks'>
        {stakedLegendaryCollection.length > 0 && <>
            <div className='locker-block'>
              <div className='locker-block-header'>
                <div className='locker-block-title'>Staked Legendary</div>
                <div className='locker-block-ctrl locker-block-ctrl-accent'>
                  <div className='locker-block-ctrl-txt'>Pending Rewards {withdrawableDividend} BNB</div>

                </div>
              </div>
              <div className='locker-items'>
              {stakedLegendaryCollection
              .map((item: NFT, i: number) => 
                <PiggyCard season={item.set} key={item.id} className='locker-item' type='legendary'>
                <div className='locker-item-ctrls'>
                </div>
              </PiggyCard>
              )}
              </div>
            </div>
          </>}
          {nftCollection.legendary.length > 0 && <>
            <div className='locker-block'>
              <div className='locker-block-header'>
                <div className='locker-block-title'>Legendary</div>
              </div>
              <div className='locker-items'>
              {nftCollection.legendary
              .map((item: NFT, i: number) => 
                <PiggyCard season={item.set} key={item.id} className='locker-item' type='legendary'>
                <div className='locker-item-ctrls'>
                </div>
              </PiggyCard>
              )}
              </div>
            </div>
          </>}
          {nftSeasons.map((season: number) => 
          <SeasonRow 
            key={`season${season}`}
            season={season}
            seasonCards={nftCollection[season]}
            forgeLegendary={forgeLegendary}
          />)}
        </div>
      </div>
      <div className='page-light radial-light radial-light-blue'></div>
      <div className='page-light radial-light radial-light-red'></div>
    </div>
  )
}


function SeasonRow({season, seasonCards, forgeLegendary} : {season: number, seasonCards: {[num: number]: NFT[]}, forgeLegendary: any}) {

  return (
    <div className='locker-block'>
            <div className='locker-block-header'>
              <div className='locker-block-title'>Season {season}</div>
              <div className='locker-block-ctrl'>
              </div>
            </div>
            <div className='locker-items'>
              {[1,2,3,4,5,6,7]
                .map((num: any) => {
                  const numCards = seasonCards[num] ? seasonCards[num].length : 0
                  const card = numCards > 0 ? seasonCards[num][0] : {id: -1, set: season, num}
                  const type = num < 5 ? 'common' : 'rare'
                  return (
                    <PiggyCard key={num} className='locker-item' type={type} num={num} withLabel={true}>
                    <div className='locker-item-qty'><span className='locker-item-qty-number'>{numCards}</span> owned</div>
                    <div className='locker-item-ctrls'>
                    <div className='locker-item-ctrl-row'>
                      </div>
                    </div>
                  </PiggyCard>
                  )
                })}
            </div>
          </div>
  )
}

export const ProfileName = () => {
  const { name } = useParams()

  const resolvedName = useContractRead({
      ...NameRegistryABI,
      functionName: 'ownerOf',
      args: [BigNumber.from(stringToBytes32(name!))], 
      enabled: !!name
    })
  
  return (<>{resolvedName.status == "success" && 
    <Profile address={resolvedName.data as unknown as string | undefined} name={name}></Profile>}
    {resolvedName.status == "error" && 
      <div className='page locker-page'>
        <div className='page-content'>
          <div className='page-title'>Not Found</div>
          <div className='page-subtitle'>No record for {name}</div>
        </div>
      </div>}
  </>)
}

export const ProfileAddress = () => {
  const { address } = useParams()

  return <Profile address={address} name={undefined}></Profile>
}

export default ProfileAddress
