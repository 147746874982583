const { REACT_APP_NETWORK } = process.env

const nameRegistryMainnet = '0xA795bE2e8c97fE628858C26D007b529a6650102f'
const gameAddressMainnet = "0xb2524a8Ce646b99C80310F842A5A292417e95abc"
const nftAddressMainnet = "0xf0302B6028aFf4b60579E3ee368fC902C9f5893B"
const marketplaceAddressMainnet = '0x1b5d5681B2729E22B8A45Fc80aD98D7cf77D242A'
const tokenAddressMainnet = '0xcd2ecd5e06b1a330789b30e8ada3d11c51503a71'
const legendaryDividendAddressMainnet = '0x5CAFc822A3f75EC180f7d9922b592Ec161d44F26'
const NFTDataAddressMainnet = '0xFc0e2F9383738cd391b1b25967EbE40cdF722B3d'
const TokenDataAddressMainnet = '0x311c36343a882ED600546611a4c7990961bE3256'
const AttackBonusAddressMainnet = '0xc09D15c02DFb0E080D7c3D1EAbC47C82F84e62ff'

const nameRegistryTestnet = '0xA795bE2e8c97fE628858C26D007b529a6650102f'
const gameAddressTestnet = "0x3e80F8de36ff63c9B2631870ac7AED24132e51ec"
const nftAddressTestnet = "0x6A2D9E6f71196C96a8182C7649F22428eb9A6d95"
const marketplaceAddressTestnet = '0xADE2B2fdD1fD44C7d2905eFCbE36a7cD349cd677'
const tokenAddressTestnet = '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7'
const legendaryDividendAddressTestnet = '0x64aA8C0EB7973cf9Fa74E2780970eA292E0892C6'
const NFTDataAddressTestnet = '0xeB118987FC06e3b7Cf79C0Fb47E735a12E69E4a7'
const TokenDataAddressTestnet = '0xa17A8efbEA4305b0af7E08830a5C649C8EC85624'
const AttackBonusAddressTestnet = '0x29b046761F69Cb1e14e0053B1A6A5a979e39c36a'

const gameAddress = REACT_APP_NETWORK === 'testnet' ? gameAddressTestnet : gameAddressMainnet
const nftAddress = REACT_APP_NETWORK === 'testnet' ? nftAddressTestnet : nftAddressMainnet
const marketplaceAddress = REACT_APP_NETWORK === 'testnet' ? marketplaceAddressTestnet : marketplaceAddressMainnet
const tokenAddress = REACT_APP_NETWORK === 'testnet' ? tokenAddressTestnet : tokenAddressMainnet
const legendaryDividendAddress = REACT_APP_NETWORK === 'testnet' ? legendaryDividendAddressTestnet : legendaryDividendAddressMainnet
const NFTDataAddress = REACT_APP_NETWORK === 'testnet' ? NFTDataAddressTestnet : NFTDataAddressMainnet
const TokenDataAddress = REACT_APP_NETWORK === 'testnet' ? TokenDataAddressTestnet : TokenDataAddressMainnet
const AttackBonusAddress = REACT_APP_NETWORK === 'testnet' ? AttackBonusAddressTestnet : AttackBonusAddressMainnet
const nameRegistryAddress = REACT_APP_NETWORK === 'testnet' ? nameRegistryTestnet : nameRegistryMainnet

interface ABI {
    address: `0x${string}`
    abi: readonly string[]
}

export const NameRegistryInterface = [
    'function setApprovalForAll(address operator, bool _approved) external',
    'function isApprovedForAll(address owner, address operator) external view returns (bool)',
    'function transferFrom(address _from, address _to, uint256 _tokenId) external',
    'function balanceOf(address owner) external view returns (uint256)',
    'function ownerOf(uint256 id) external view returns (address)',
    'function reverseResolve(address owner) external view returns (uint256)',
    'function mintName(uint256 name) external',
    'function setReverseRecord(uint256 name) external',
    'function tokenOfOwnerByIndex(address owner, uint256 index) external view returns (uint256)',
    'function nameCollectionOf(address owner) external view returns (uint256[] memory)'
] as const

export const NameRegistryABI: ABI = {
    address: nameRegistryAddress,
    abi: NameRegistryInterface
}

export const AttackBonusInterface = [
    'function currentBoostPercentage() external view returns (uint256)',
    'function nextBoost() external view returns (uint256[2] memory)',
    'function timeToNextDay() public view returns (uint256)',
    'event Attack(address indexed player, address indexed team, uint256 damage, uint256 amount)',
] as const

export const AttackBonusABI: ABI = {
    address: AttackBonusAddress,
    abi: AttackBonusInterface
}

export const TokenDataInterface = [
    'function tokenInfo(address tokenAddress) external view returns (uint8, string memory, string memory)',
] as const
export const TokenDataABI: ABI = {
    address: TokenDataAddress,
    abi: TokenDataInterface
}

export const NFTDataInterface = [
    'function nftCollectionOf(address owner) external view returns (uint256[][] memory nfts)'
] as const
export const NFTDataABI: ABI = {
    address: NFTDataAddress,
    abi: NFTDataInterface
}

export const legendaryDividendInterface = [
    'function stakedNFTCollectionOf(address owner) external view returns (uint256[][] memory)',
    'function stakeLegendary(uint256 tokenId) external',
    'function unstakeLegendary(uint256 tokenId) external',
    'function withdrawDividend() public',
    'function accumulativeDividendOf(address _owner) public view returns(uint256)',
    'function withdrawnDividendOf(address _owner) public view returns(uint256)',
    'function withdrawableDividendOf(address _owner) public view returns(uint256)',
    'function totalSupply() external view returns (uint256)'
] as const
export const legendaryDividendABI: ABI = {
    address: legendaryDividendAddress,
    abi: legendaryDividendInterface
}

export const tokenInterface = [
    'function balanceOf(address account) external view returns (uint256)',
    'function transfer(address recipient, uint256 amount) external returns (bool)',
    'function allowance(address _owner, address spender) external view returns (uint256)',
    'function approve(address spender, uint256 amount) external returns (bool)',
    'function transferFrom(address sender, address recipient, uint256 amount) external returns (bool)'
] as const
export const tokenABI: ABI = {
    address: tokenAddress,
    abi: tokenInterface
}

export const marketplaceInterface = [
    'function getFeePercentage() external view returns (uint256)',
    'function submitListing(uint256 _id, uint256 price) external returns (uint256)',
    'function buyListing(uint256 listingId) external payable',
    'function cancelListing(uint256 listingId) external',
    'function changeListingPrice(uint256 listingId, uint256 newPrice) external',
    'function getListingsByCard(uint16 _set, uint8 _number) external view returns (Listing[] memory)',
    'function getListingsBySeller(address _seller) external view returns (Listing[] memory all)',
    'function getListingIdsByCard(uint16 _set, uint8 _number) external view returns (uint256[] memory)',
    'function getListingIdsPricesByCard(uint16 _set, uint8 _number) external view returns (uint256[][] memory)',
    'function getListingIdsPricesBySeller(address _seller) external view returns (uint256[][] memory)',
    'function getListingIdsBySeller(address _seller) external view returns (uint256[] memory)',
    'function getListing(uint256 _listingId) external view returns (uint256 listingId, uint256 nftId, address seller, uint256 price, uint16 set, uint8 number, bool enabled)'
] as const
export const marketplaceABI: ABI = {
    address: marketplaceAddress,
    abi: marketplaceInterface
}

export const nftInterface = [
    'function setApprovalForAll(address operator, bool _approved) external',
    'function isApprovedForAll(address owner, address operator) external view returns (bool)',
    'function transferFrom(address _from, address _to, uint256 _tokenId) external',
    'function balanceOf(address owner) external view returns (uint256)'
] as const
export const nftABI: ABI = {
    address: nftAddress,
    abi: nftInterface
}

export const gameInterface = [
    'function switchSeason() external',
    'function getActiveTeamsData() external view returns (uint256[][] memory)',
    'function seasonExpiration() external view returns (uint256)',
    'function totalVolume() external view returns (uint256)',
    'function redeemFee() external view returns (uint256)',
    'function teamDamageOf(address teamId) external view returns(uint256)',
    'function open() external view returns (bool)',
    'function getRareChances() external view returns(uint16, uint16, uint16)',
    'function teamOf(address _player) external view returns(address)',
    'function season() external view returns (uint16)',
    'function boosterPackBalanceOf(address _player) external view returns(uint256)',
    'function getThresholds() external view returns(uint256, uint256, uint256, uint256)',
    'function teamWinsOf(address teamId) external view returns(uint32)',
    'function getActiveTeams() external view returns(address[] memory)',
    'function tokenBalanceOf(address player) external view returns (uint256)',
    'function unclaimedBoosterPacksOf(address player) external view returns (uint256)',
    'function attack(address team, bytes calldata referralCode, bool referral, uint256 amountOutMin) external payable',
    'function join(address teamTokenAddress) external',
    'function claimBoosterPacks(uint256 amountOutMin) external payable',
    'function unpackBoosterPack() external',
    'function nftCollectionOf(address owner) external view returns (uint256[][] memory nfts)',
    'function forgeLegendary(uint256[] calldata ids) external',
    'function reforge(uint256[] calldata ids) external',
    'function maxUnclaimed() external view returns (uint256)',
    'function totalExperienceOf(address _player) external view returns(uint256)',
    'event ReceivedBoosterPack(address indexed requester, uint256 randomness)',
    'event Attack(address indexed player, address indexed team, uint256 amount)'
] as const
export const gameABI: ABI = {
    address: gameAddress,
    abi: gameInterface
}