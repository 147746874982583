import { BigNumber, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useState, useEffect } from 'react'
import { useContractRead } from 'wagmi'
import { Team } from './interfaces'
import { gameABI } from './volumewars'

export function useTeams(): Team[]
{
    const [teams, setTeams] = useState<Team[]>([])
    
    const teamAddresses = useContractRead({
    ...gameABI,
    functionName: 'getActiveTeams'
  })
    const teamData = useContractRead({
    ...gameABI,
    functionName: 'getActiveTeamsData',
    watch: true
  })

    useEffect(() => {
        if (!teamAddresses.data || !teamData.data) {
          return
        }
        const addresses = teamAddresses.data as string[]
        const teamDataArray: BigNumber[][] = teamData.data as BigNumber[][]
        let allTeams: Team[] = []
        for (var teamArray of teamDataArray) {
          const team: Team = {
            address: addresses[teamArray[0].toNumber()],
            wins: teamArray[1].toNumber(),
            damagePoints: teamArray[2]
          }
          allTeams.push(team)
        }
        setTeams(allTeams.sort((a, b) => parseFloat(formatEther(a.damagePoints.sub(b.damagePoints)))))
      }, [teamAddresses.status, teamData.status])

    return teams
}