import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

import useAppState from '../../services/appState'

import './styles.scss'

const MobileNav = () => {
  const setIsMobileMenuOpened = useAppState(state => state.setIsMobileMenuOpened)

  return (
    <Modal
      open={true}
      BackdropComponent={Backdrop}
      className='mobile-nav modal'
    >
      <div className='mobile-nav-content modal-content'>
        <a href='https://docs.volumewars.com/' target='_blank' rel='noopener noreferrer' className='mobile-nav-link'>Documents</a>
        <Link
          to='/locker'
          className='mobile-nav-link'
          onClick={() => { setIsMobileMenuOpened(false) }}
        >NFT Locker</Link>
        <Link
          to='/game'
          className='mobile-nav-link'
          onClick={() => { setIsMobileMenuOpened(false) }}
        >Game</Link>
        <Link
          to='/marketplace'
          className='mobile-nav-link'
          onClick={() => { setIsMobileMenuOpened(false) }}
        >Marketplace</Link>
        <Link
          to='/leaderboards'
          className='mobile-nav-link'
          onClick={() => { setIsMobileMenuOpened(false) }}
        >Leaderboards</Link>
        <div
          className='mobile-nav-close'
          onClick={() => { setIsMobileMenuOpened(false) }}
        >
          <img src='/content/img/icons/close.png' />
        </div>
      </div>
    </Modal>
  )
}

export default MobileNav
