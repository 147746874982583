import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import PiggyCard from '../../shared/piggy-card'
import useAppState from '../../../services/appState'

import './styles.scss'
import { useAccount, useContract, useContractEvent, useContractRead, useProvider, useSigner } from 'wagmi'
import { useNFTCollection } from '../../../services/app/nftCollection'
import { NFT } from '../../../services/app/interfaces'
import { BigNumber, ethers, utils } from 'ethers'
import { gameABI, legendaryDividendABI, nftABI } from '../../../services/app/volumewars'
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'
import { formatEther } from 'ethers/lib/utils'
import { getLegendaryIngredients } from '../../../services/app/utils'
import { toast } from 'react-toastify'
import { getAmountOutMin } from '../../../services/app/slippage'
const { REACT_APP_NETWORK } = process.env

const Locker = () => {
  const sellModalNFT = useAppState(state => state.sellModalNFT)
  const setSellModalNFT = useAppState(state => state.setSellModalNFT)
  const sendModalNFT = useAppState(state => state.sendModalNFT)
  const setSendModalNFT = useAppState(state => state.setSendModalNFT)

  const signer = useSigner()
  const account = useAccount()

  const gameContract: ethers.Contract = useContract({
    ...gameABI,  signerOrProvider: signer.data
  })!
  const nftContract: ethers.Contract = useContract({
    ...nftABI,  signerOrProvider: signer.data
  })!
  const legendaryDividendContract: ethers.Contract = useContract({
    ...legendaryDividendABI,  signerOrProvider: signer.data
  })!

  const unclaimedBalance = useContractRead({
    ...gameABI,
    functionName: 'unclaimedBoosterPacksOf',
    args: [account.address]
  })
  const boosterPackBalance = useContractRead({
    ...gameABI,
    functionName: 'boosterPackBalanceOf',
    args: [account.address]
  })

  useContractEvent({
    ...gameABI,
    eventName: 'ReceivedBoosterPack',
    listener(requester, randomness) {
      try {
        console.log(requester)
        if (account.address == requester) {
          boosterPackBalance.refetch()
          toast("Received booster pack(s).")
        }
      } catch (e) {
        console.log(requester, randomness)
      }
    },
  })

  const redeemFee = useContractRead({
    ...gameABI,
    functionName: 'redeemFee'
  })
  
  const boosterPacks = useMemo(() => {
    if (!boosterPackBalance.data) {
      return 0
    }
    return (boosterPackBalance.data as unknown as BigNumber).toNumber()
  }, [boosterPackBalance.data])

  const unclaimedBoosterPacks = useMemo(() => {
    if (!unclaimedBalance.data) {
      return 0
    }
    return (unclaimedBalance.data as unknown as BigNumber).toNumber()
  }, [unclaimedBalance.data])

  const provider = useProvider()

  const claimBoosterPack = async () => {
    if (!await checkChainId(gameContract)) {
      return
    }
    const amountOutMin = REACT_APP_NETWORK == "testnet" ? 0 : getAmountOutMin(utils.formatEther(redeemFee.data as any as BigNumber), '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD', provider)
    if (await waitTxWithToast(gameContract.claimBoosterPacks(amountOutMin, {value: redeemFee.data}))) {
      await boosterPackBalance.refetch()
      await unclaimedBalance.refetch()
      toast("You may have to wait a few minutes until you receive your claimed booster packs.")
    }
  }
  const openBoosterPack = async () => {
    if (!await checkChainId(gameContract)) {
      return
    }
    if (await waitTxWithToast(gameContract.unpackBoosterPack())) {
      await boosterPackBalance.refetch()
      await unclaimedBalance.refetch()
    }
  }


  const [nftCollection, refetchNFTCollection] = useNFTCollection(account.address)
  const [stakedLegendaryCollection, setStakedLegendaryCollection] = useState<NFT[]>([])

  const isApprovedForAll = useContractRead({
    ...nftABI,
    functionName: 'isApprovedForAll',
    args: [account.address, legendaryDividendABI.address] 
  })

  const approveForAll = async () => {
    if (!await checkChainId(gameContract)) {
      return
    }
    if (await waitTxWithToast(nftContract.setApprovalForAll(legendaryDividendABI.address, true))) {
      await isApprovedForAll.refetch()
    }
  }

  const stakedNFTCollection = useContractRead({
    ...legendaryDividendABI,
    functionName: 'stakedNFTCollectionOf',
    args: [account.address]
  })

  const withdrawableDividendData = useContractRead({
    ...legendaryDividendABI,
    functionName: 'withdrawableDividendOf',
    args: [account.address]
  })
  const withdrawableDividend = useMemo(() => {
    if (!BigNumber.isBigNumber(withdrawableDividendData.data)){
      return 0
    }
    const withdrawable = withdrawableDividendData.data as any as BigNumber
    return parseFloat(formatEther(withdrawable))

  }, [withdrawableDividendData.data])

  const withdrawDividend = async () => {
    if (!await checkChainId(legendaryDividendContract)) {
      return
    }
    if (await waitTxWithToast(legendaryDividendContract.withdrawDividend())) {
      await withdrawableDividendData.refetch()
    }
  }

  const stake = async (tokenId: number) => {
    if (!await checkChainId(legendaryDividendContract)) {
      return
    }
    if (await waitTxWithToast(legendaryDividendContract.stakeLegendary(tokenId))) {
      await stakedNFTCollection.refetch()
      refetchNFTCollection({args: [account.address]})
    }
  }

  const unstake = async (tokenId: number) => {
    if (!await checkChainId(legendaryDividendContract)) {
      return
    }
    if (await waitTxWithToast(legendaryDividendContract.unstakeLegendary(tokenId))) {
      await stakedNFTCollection.refetch()
      refetchNFTCollection({args: [account.address]})
    }
  }

  useEffect(() => {
    if (!stakedNFTCollection.data) {
      return
    }
    const stakedArray: BigNumber[][] = stakedNFTCollection.data as BigNumber[][]
    let ordered: NFT[] = []
    for (var nftarray of stakedArray) {
      const item: NFT = {
        id: nftarray[0].toNumber(),
        set: nftarray[1].toNumber(),
        num: nftarray[2].toNumber()
      }
      ordered.push(item)
    }
    setStakedLegendaryCollection(ordered)

  }, [stakedNFTCollection.data])

  useEffect(() => {
    refetchNFTCollection({args: [account.address]})
    stakedNFTCollection.refetch()
    isApprovedForAll.refetch()
    withdrawableDividendData.refetch()
  }, [account.address])

  useEffect(() => {
    refetchNFTCollection({args: [account.address]})
  }, [boosterPacks])

  async function forgeLegendary(seasonCards: {[num: number]: NFT[]}) {
    const ingredients = getLegendaryIngredients(seasonCards)
    if (!ingredients) {
      alert("You need a season's full set to forge a legendary")
    } else {
      if (!await checkChainId(gameContract)) {
        return
      }
      if (await waitTxWithToast(gameContract.forgeLegendary(ingredients.map((ingredient) => ingredient.id)))) {
        refetchNFTCollection({args: [account.address]})
      }
    }
  }

  useEffect(() => {
    if (sendModalNFT === false) {
      refetchNFTCollection({args: [account.address]})
    }
  }, [sendModalNFT])

  useEffect(() => {
    if (sellModalNFT === false) {
      refetchNFTCollection({args: [account.address]})
    }
  }, [sellModalNFT])

  const nftSeasons = useMemo(() =>
    Object.keys(nftCollection)
    .filter((v: string) => (v != 'legendary'))
    .map((seasonStr: string) => parseInt(seasonStr)),
    [nftCollection])

  return (
    <div className='page locker-page'>
      <div className='page-content'>
        <div className='page-title'>NFT Locker</div>
        <div className='page-subtitle'>Claim Rewards & Forge Legendary NFTs</div>
        <div className='locker-boosters'>
          <div className='locker-booster booster-claim card'>
            <div className='booster-claim-content card-content'>
              <img src='/content/img/box.png' />
              <div className={`locker-booster-btn btn-primary btn-primary-gradient ${unclaimedBoosterPacks == 0 && 'disabled'}`} onClick={() => claimBoosterPack()}>
                <div className='btn-primary-content'>Claim Booster Packs</div>
              </div>
              <div className='locker-booster-number' >{unclaimedBoosterPacks}</div>
            </div>
          </div>
          <div className='locker-booster booster-open card'>
            <div className='booster-open-content card-content'>
              <div className='booster-open-title'>Booster Packs</div>
              <div className='booster-open-txt'>Booster packs claimed together in a single transaction will also be opened together in one transaction</div>
              <div className='booster-open-note'>
                <div className='booster-open-note-bold'>Please note:</div>
                <div className='booster-open-note-regular'>After you claim booster packs, they will show up here, ready to be opened</div>
              </div>
              <div className={`locker-booster-btn btn-primary btn-primary-gradient ${boosterPacks == 0 && 'disabled'}`} onClick={() => openBoosterPack()}>
                <div className='btn-primary-content'>Open Booster Packs</div>
              </div>
              <div className='locker-booster-number'>{boosterPacks}</div>
            </div>
          </div>
        </div>
        <div className='locker-reroll card card-blue'>
          <div className='locker-reroll-content card-content'>
            <div className='locker-reroll-txt'>Re-roll Common NFT</div>
            <Link to='/burn' className='locker-reroll-btn btn-primary btn-primary-blue'>
              <div className='btn-primary-content'>Re-roll</div>
            </Link>
          </div>
        </div>

        <div className='locker-reroll card card-blue'>
          <div className='locker-reroll-content card-content'>
            <div className='locker-reroll-txt'>Nickname Management</div>
            <Link to='/locker/name' className='locker-reroll-btn btn-primary btn-primary-blue'>
              <div className='btn-primary-content'>Names</div>
            </Link>
          </div>
        </div>
        
        <div className='locker-blocks'>
        {stakedLegendaryCollection.length > 0 && <>
            <div className='locker-block'>
              <div className='locker-block-header'>
                <div className='locker-block-title'>Staked Legendary</div>
                <div className='locker-block-ctrl locker-block-ctrl-accent'>
                  <div className='locker-block-ctrl-txt'>Pending Rewards {withdrawableDividend} BNB</div>
                  <div className='locker-block-ctrl-btn locker-block-ctrl-btn-claim btn-primary btn-primary-red'>
                    <div className='btn-primary-content' onClick={() => withdrawDividend()}>Claim</div>
                  </div>
                </div>
              </div>
              <div className='locker-items'>
              {stakedLegendaryCollection
              .map((item: NFT, i: number) => 
                <PiggyCard season={item.set} key={item.id} className='locker-item' type='legendary'>
                <div className='locker-item-ctrls'>
                  <div className='locker-item-ctrl locker-item-stake btn-primary btn-primary-red'>
                    <div className='btn-primary-content' onClick={() => unstake(item.id)}>Unstake</div>
                    </div>
                </div>
              </PiggyCard>
              )}
              </div>
            </div>
          </>}
          {nftCollection.legendary.length > 0 && <>
            <div className='locker-block'>
              <div className='locker-block-header'>
                <div className='locker-block-title'>Legendary</div>
              </div>
              <div className='locker-items'>
              {nftCollection.legendary
              .map((item: NFT, i: number) => 
                <PiggyCard season={item.set} key={item.id} className='locker-item' type='legendary'>
                <div className='locker-item-ctrls'>
                  {isApprovedForAll.data ?
                  <div className='locker-item-ctrl locker-item-stake btn-primary btn-primary-red' onClick={() => stake(item.id)}>
                    <div className='btn-primary-content'>Stake</div>
                  </div>:
                  <div className='locker-item-ctrl locker-item-stake btn-primary btn-primary-red' onClick={() => approveForAll()}>
                    <div className='btn-primary-content'>Approve Staking</div>
                  </div>}
                  <div className='locker-item-ctrl-row'>
                    <div onClick={() => { setSendModalNFT(item) }} className='locker-item-ctrl locker-item-send btn-primary btn-primary-red'><div className='btn-primary-content'>Send</div></div>
                    <div onClick={() => { setSellModalNFT(item) }} className='locker-item-ctrl locker-item-sell btn-primary btn-primary-blue'><div className='btn-primary-content'>Sell</div></div>
                  </div>
                </div>
              </PiggyCard>
              )}
              </div>
            </div>
          </>}
          {nftSeasons.map((season: number) => 
          <SeasonRow 
            key={`season${season}`}
            season={season}
            seasonCards={nftCollection[season]}
            forgeLegendary={forgeLegendary}
          />)}
        </div>
      </div>
      <div className='page-light radial-light radial-light-blue'></div>
      <div className='page-light radial-light radial-light-red'></div>
    </div>
  )
}

export default Locker

function SeasonRow({season, seasonCards, forgeLegendary} : {season: number, seasonCards: {[num: number]: NFT[]}, forgeLegendary: any}) {

  const setSellModalNFT = useAppState(state => state.setSellModalNFT)
  const setSendModalNFT = useAppState(state => state.setSendModalNFT)
  const legendaryIngredients = useMemo(() => getLegendaryIngredients(seasonCards), [season, seasonCards])

  return (
    <div className='locker-block'>
            <div className='locker-block-header'>
              <div className='locker-block-title'>Season {season}</div>
              <div className='locker-block-ctrl'>
                <div className={`locker-block-ctrl-btn locker-block-ctrl-btn-forge btn-primary btn-primary-red ${!legendaryIngredients && 'disabled'}`}>
                  <div className='btn-primary-content' onClick={() => forgeLegendary(seasonCards)}>Forge Legendary</div>
                </div>
              </div>
            </div>
            <div className='locker-items'>
              {[1,2,3,4,5,6,7]
                .map((num: any) => {
                  const numCards = seasonCards[num] ? seasonCards[num].length : 0
                  const card = numCards > 0 ? seasonCards[num][0] : {id: -1, set: season, num}
                  const type = num < 5 ? 'common' : 'rare'
                  return (
                    <PiggyCard key={num} className='locker-item' type={type} num={num} withLabel={true}>
                    <div className='locker-item-qty'><span className='locker-item-qty-number'>{numCards}</span> owned</div>
                    <div className='locker-item-ctrls'>
                    <div className='locker-item-ctrl-row'>
                      {numCards > 0 && <>
                        <div onClick={() => { setSendModalNFT(card) }} className='locker-item-ctrl locker-item-send btn-primary btn-primary-red'><div className='btn-primary-content'>Send</div></div>
                        {card.num > 4 && <div onClick={() => { setSellModalNFT(card) }} className='locker-item-ctrl locker-item-sell btn-primary btn-primary-blue'><div className='btn-primary-content'>Sell</div></div>}
                        </>}
                      </div>
                    </div>
                  </PiggyCard>
                  )
                })}
            </div>
          </div>
  )
}