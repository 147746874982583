import React from 'react'

import './styles.scss'

type Props = {
  className?: string
  type: 'common' | 'rare' | 'legendary'
  num?: any
  season?: number
  withLabel?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

const PiggyCard: React.FC<Props> = ({ className, type, num, season, withLabel, children }) => {
  return (
    <div className={`piggy-card-wrapper ${type} ${className || ''} ${withLabel ? 'with-label' : ''}`}>
      <div className='piggy-card-img'>
        <img src={`/content/img/cards/${type}${num > 0 ? num : ''}.${type === 'legendary' ? 'gif' : 'png'}`} />
      </div>
      <div className='piggy-card card'>
        <div className='piggy-card-content card-content' data-season={season ? `Season ${season}` : ''}>
          {children}
        </div>
      </div>
    </div>
  )
}

type NameProps = {
  className?: string
  type: 'common' | 'rare' | 'legendary'
  num?: any
  name?: string
  withLabel?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

export const NameCard: React.FC<NameProps> = ({ className, name, withLabel, children }) => {
  return (
    <div className={`piggy-card-wrapper legendary ${className || ''} ${withLabel ? 'with-label' : ''}`}>
      <div className='piggy-card-img'>
      </div>
      <div className='piggy-card card'>
        <div className='piggy-card-content card-content' data-season={name ? name : ''}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PiggyCard
