import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

import useAppState from '../../../services/appState'

import './styles.scss'
import { useContract, useSigner } from 'wagmi'
import { NameRegistryABI } from '../../../services/app/volumewars'
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'
import { stringToBytes32 } from '../../../services/app/utils'

const RegisterNameModal = () => {
  const mintNameModal = useAppState(state => state.mintNameModal) as boolean
  const setMintNameModal = useAppState(state => state.setMintNameModal)
  const [value, setValue] = useState("")
  const [error, setError] = useState("")
  const onChange = (e: any) => setValue(e.target.value)
  
  const signer = useSigner()

  const nameContract = useContract({
    ...NameRegistryABI, signerOrProvider: signer.data
  })!
  useEffect(() => {
    setError("")
  }, [value])
  const mintNFT = async () => {
    if (value.length < 5 || value.length > 31) {
      return
    }
    if (!await checkChainId(nameContract)) {
      return
    }
    const nameb32 = stringToBytes32(value)
    try {
      const owner = await nameContract.ownerOf(nameb32)
      setError(`Name already registered to address: ${owner}`)
    } catch (e) {
      if (await waitTxWithToast(nameContract.mintName(nameb32))) {
        setMintNameModal(false)
        setError("")
      }
    }
  }
  
  return (
    <>
      {mintNameModal && <Modal
        open={!!mintNameModal}
        BackdropComponent={Backdrop}
        onClose={() => { setMintNameModal(false) }}
        className='send-modal modal'
      >
        <div className='modal-content card card-blue'>
          <div className='card-content'>
            <div className='send-content'>
              <div className='send-info'>Choose a unique name for your profile.<br />This will be minted as a tradeable NFT.
              <br />Your name will be displayed on the leaderboard.
              </div>
              <div className='send-txt'>Pick an alphanumeric name from 5 to 30 characters:</div>
              {error && <div className='send-txt'>{error}</div>}
              <div className='send-input-wrapper'><input type='text' placeholder='Name to mint' pattern="[-_A-Za-z0-9]{5,30}" onChange={onChange}/></div>
              <div className='send-btn btn-primary btn-primary-red' onClick={() => mintNFT()}>
                <div className='btn-primary-content'>Mint Name</div>
              </div>
            </div>
          </div>
          <img onClick={() => { setMintNameModal(false) }} className='close-btn' src='/content/img/icons/close.png' />
        </div>
      </Modal>}
    </>
  )
}

export default RegisterNameModal
