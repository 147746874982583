import { BigNumber, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useState, useEffect } from 'react'
import { useContractRead } from 'wagmi'
import { Listing } from './interfaces'
import { marketplaceABI } from './volumewars'

export function useListingsByCard({set, num}: {set: number, num: number}): [
  Listing[],
  boolean | undefined,
  Function]
{
    const [listings, setListings] = useState<Listing[]>([])

    const listingsByCard = useContractRead({
    ...marketplaceABI,
    functionName: 'getListingIdsPricesByCard',
    args: [set, num]
  })

    useEffect(() => {
        listingsByCard.refetch()
    }, [set, num])

    useEffect(() => {
        if (!listingsByCard.data) {
          return
        }
        const listingDataArray: BigNumber[][] = listingsByCard.data as BigNumber[][]
        let allListings: Listing[] = []
        for (var listingData of listingDataArray) {
          const listing: Listing = {
            listingId: listingData[0].toNumber(),
            nftId: listingData[1].toNumber(),
            price: listingData[2],
            set,
            num,
            enabled: true
          }
          allListings.push(listing)
        }
        setListings(allListings.sort((a, b) => parseFloat(formatEther(a.price.sub(b.price)))))
      }, [listingsByCard.data])

    return [listings, listingsByCard.isLoading, listingsByCard.refetch]
}

export function useListingsBySeller(seller?: string): [
  Listing[],
  boolean | undefined,
  Function]
{
    const [listings, setListings] = useState<Listing[]>([])

    const listingsBySeller = useContractRead({
    ...marketplaceABI,
    functionName: 'getListingIdsPricesBySeller',
        args: [seller]
  })

    useEffect(() => {
        listingsBySeller.refetch()
    }, [seller])

    useEffect(() => {
        if (!listingsBySeller.data) {
          return
        }
        const listingDataArray: BigNumber[][] = listingsBySeller.data as BigNumber[][]
        let allListings: Listing[] = []
        for (var listingData of listingDataArray) {
          const listing: Listing = {
            listingId: listingData[0].toNumber(),
            nftId: listingData[1].toNumber(),
            price: listingData[2],
            set: listingData[3].toNumber(),
            num: listingData[4].toNumber(),
            seller,
            enabled: !!listingData[5].toNumber()
          }
          allListings.push(listing)
        }
        setListings(allListings.sort((a, b) => b.listingId - a.listingId))
      }, [listingsBySeller.data])

    return [listings, listingsBySeller.isLoading, listingsBySeller.refetch]
}
