import React from 'react'
import { useMemo, useState, useEffect } from 'react'
import { ethers } from "ethers"

import { useContractRead, useAccount, useContract, useSigner } from 'wagmi'

import PiggyCard from '../../shared/piggy-card'
import Dropdown from '../../shared/dropdown'

import { gameABI } from "../../../services/app/volumewars"
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'
import { useBurnableNFT } from '../../../services/app/nftCollection'

import './styles.scss'
import { toast } from 'react-toastify'

const Burn = () => {
  const { data: signer, isError, isLoading } = useSigner()
  const account = useAccount()

  const seasonContract = useContractRead({
    ...gameABI,
    functionName: 'season'
  })

  const gameContract: ethers.Contract = useContract({
    ...gameABI,  signerOrProvider: signer
  })!


  const [selectSeason, setSelectSeason] = useState([{ label: 'Season 1', value: '1'}])
  const [season, setSeason] = useState({ value: { value: selectSeason[0].value, label: selectSeason[0].label } })

  useEffect(() => {
    if (seasonContract.isLoading) return

    const seasonNum = seasonContract.data as unknown as number

    const options = []
    for (let s = 1; s <= seasonNum; s++)
      options.push({ label: `Season ${s}`, value: s.toString() })

    setSelectSeason(options.reverse())
  }, [seasonContract.data])

  const seasonNumber = useMemo(() => parseInt(season.value.value), [season.value.value])
  const [burnableCards, readNFTCollection] = useBurnableNFT(account.address, seasonNumber)

  useEffect(() => {
    if (!account.address) return
    readNFTCollection({args: [account.address]})
  },  [account.address])

  async function reforgeCards() {
    if (!burnableCards || burnableCards.length < 5) {
      toast.error("Insufficient Common NFTs to reforge")
    } else {
      if (!await checkChainId(gameContract)) {
        return
      }
      if (await waitTxWithToast(gameContract.reforge(burnableCards.map((ingredient) => ingredient.id)))) {
        readNFTCollection({args: [account.address]})
      }
    }
  }

  const handleChangeSeason = (value: any) => {
    setSeason({ value })
  }

  return (
    <div className='page boosters-page'>
      <div className='page-content'>
        <div className='page-title'>Earn Booster Packs</div>
        <div className='page-subtitle'>Burn 5 common cards from any season and receive a Tier 2 booster pack</div>
        <div className='boosters'>
          <div className='booster booster-box card'>
            <div className='booster-box-content card-content'>
              <img src='/content/img/box.png' />
            </div>
          </div>
          <div className='booster booster-burn card'>
            <div className='booster-burn-content card-content'>
              <div className='booster-burn-title'>Burn</div>
              <div className='booster-burn-txt'>Choose which season<br /> to <span className='booster-burn-txt-red'>burn</span> cards from</div>
              <Dropdown
                className='booster-burn-season'
                options={selectSeason}
                placeholder={selectSeason[0].label}
                onChange={handleChangeSeason}
              />
              <div className={`booster-btn btn-primary btn-primary-red ${burnableCards.length != 5 && 'disabled'}`} onClick={reforgeCards}>
                <div className='btn-primary-content'>Burn</div>
              </div>
            </div>
          </div>
        </div>
        {burnableCards.length >= 5 && 
        <>
        <div className='burned-cards-title'>These 5 cards will be burned:</div>
        <div className='burned-cards-list'>
          { burnableCards.map((item) =>
            <PiggyCard className='burned-card' type='common' key={item.id} num={item.num.toString()} withLabel={true}>
              <div className='burned-card-title'>Season {item.set} #{item.num}</div>
              <div className='burned-card-id'>ID: {item.id}</div>
            </PiggyCard>) }
        </div>
        </>}
        {burnableCards.length < 5 && 
        <>
        <div className='burned-cards-title'>Insufficient common cards from this season: <br></br> At least 5 are necessary</div>
        <div className='burned-cards-list'>
          { burnableCards.map((item) =>
            <PiggyCard className='burned-card' type='common' key={item.id} num={item.num.toString()} withLabel={true}>
              <div className='burned-card-title'>Season {item.set} #{item.num}</div>
              <div className='burned-card-id'>ID: {item.id}</div>
            </PiggyCard>) }
        </div>
        </>}
      </div>
      <div className='page-light radial-light radial-light-blue'></div>
      <div className='page-light radial-light radial-light-red'></div>
    </div>
  )
}

export default Burn
