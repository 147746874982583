import React from 'react'

import './styles.scss'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-links footer-links-mobile'>
        <a href='#'>Terms of Service</a>
      </div>
      <div className='footer-links'>
        <a href='https://twitter.com/volumewars' target="_blank" ><img src='/content/img/footer/twitter.png' /></a>
        <a href='https://discord.gg/kUfm4sCk2P' target="_blank" ><img src='/content/img/footer/discord.png' /></a>
        <a className='footer-link-terms' href='#'>Terms of Service</a>
      </div>
      <div className='footer-divider'></div>
      <div className='footer-copyrights'>Copyright 2022 Volume Wars | All Rights Reserved</div>
      <div className='footer-divider'></div>
      <div className='footer-poweredby'>
        <span>Powered By:</span>
        <img src='/content/img/footer/shillx.png' />
      </div>
    </div>
  )
}

export default Footer
