import React from 'react'
import ReactDOM from 'react-dom/client'
import { WagmiConfig, createClient, createStorage } from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { InjectedConnector } from '@wagmi/core'
import { Buffer } from "buffer";
// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

import App from './components/app'

import './styles.scss'
import { chains, provider } from './services/provider'

const client = createClient({
  autoConnect: true,
  provider,
  storage: createStorage({ storage: window.localStorage }),
  connectors: [
    new InjectedConnector({ chains }),
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      }
    }),
  ],
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <App />
    </WagmiConfig>
  </React.StrictMode>
)
