import { BigNumber, ethers, utils } from 'ethers'
import React, { useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { useAccount, useContract, useContractEvent, useContractRead, useProvider, useSigner } from 'wagmi'
import { Team } from '../../../../services/app/interfaces'
import { getAmountOutMin } from '../../../../services/app/slippage'
import { checkChainId, waitTxWithToast } from '../../../../services/app/transaction'
import { getLogo } from '../../../../services/app/utils'
import { AttackBonusABI, gameABI } from '../../../../services/app/volumewars'

import useAppState from '../../../../services/appState'
import { timeout } from '../../../../services/misc'
const { REACT_APP_NETWORK } = process.env

import './styles.scss'

const giveDamageAnimation = async (el: HTMLDivElement, value: string, count: number) => {
  await new Audio('/content/audio/hitmarker.mp3').play()
  el.classList.add('animate')
  const node = document.createElement('div')
  node.className = `damage-value ${count % 2 === 0 ? 'damage-value-left' : 'damage-value-right'}`
  node.innerHTML = `${value}`
  el.appendChild(node)
  await timeout(1000)
  el.classList.remove('animate')
}

function getTierData(thresholds: BigNumber[] | undefined, rareChances: number[] | undefined){
  if (!thresholds || !rareChances) return []
  const commons = [
    "1 in 3",
    "0 to 1",
    "0 to 2",
    "1 to 3"
  ]
  return thresholds.map((threshold, i) => {
    var desc = "None"
    if (i > 0) {
      desc = "1 in "+rareChances[i-1]
    }
    return {
      tier: i+1,
      price: utils.formatEther(threshold),
      points: parseInt((parseFloat(utils.formatEther(threshold))*1000).toString()).toString(),
      rareChanceDesc: desc,
      commonDesc: commons[i]
    }
  })
}

const Attack = ({boostMultiplier} : {boostMultiplier: string}) => {
  const attackerEl = useRef<HTMLDivElement | null>(null)
  const victimEl = useRef<HTMLDivElement | null>(null)

  async function showDamage(el: React.MutableRefObject<HTMLDivElement | null>, amount: string, counter: number) {
    if (el.current) {
      await giveDamageAnimation(el.current, amount, counter)
    }
  }

  const thresholds = useContractRead({
    ...gameABI,
    functionName: 'getThresholds'
  })
  const rareChances = useContractRead({
    ...gameABI,
    functionName: 'getRareChances'
  })

  const cards = useMemo(() => 
  getTierData(thresholds.data as BigNumber[] | undefined, rareChances.data as number[] | undefined),
  [thresholds.data, rareChances.data])

  const ownTeam = useAppState(state => state.ownTeam) as Team
  const attackedTeam = useAppState(state => state.attackedTeam) as Team
  const teamNames = useAppState(state => state.teamNames)
  const attackedTeamDamage = useMemo(() => parseInt(utils.formatUnits(attackedTeam.damagePoints, 15)), [attackedTeam.damagePoints])
  const ownTeamDamage = useMemo(() => parseInt(utils.formatUnits(ownTeam.damagePoints, 15)), [ownTeam.damagePoints])
  const setAttackedTeam = useAppState(state => state.setAttackedTeam)

  const signer = useSigner()
  const account = useAccount()
  const provider = useProvider()
  const gameContract: ethers.Contract = useContract({
    ...gameABI,  signerOrProvider: signer.data
  })!

  const unclaimedBalance = useContractRead({
    ...gameABI,
    functionName: 'unclaimedBoosterPacksOf',
    args: [account.address] 
  })

  const maxUnclaimed = useContractRead({
    ...gameABI,
    functionName: 'maxUnclaimed'
  })

  async function attack(price: string) {
    if ((unclaimedBalance.data as unknown as BigNumber).toNumber() >= (maxUnclaimed.data as unknown as BigNumber).toNumber()) {
      // Too many unclaimed
      toast.error("Claim your booster packs from the locker before attacking again")
      await unclaimedBalance.refetch()
      return
    }
    let amountOutMin = "0"
    if (REACT_APP_NETWORK != "testnet") {
      // Get slippage
      amountOutMin = await getAmountOutMin((parseFloat(price)/2).toString(), ownTeam.address, provider)
      console.log(amountOutMin)
    }
    if (!await checkChainId(gameContract)) {
      return
    }
    try {
      if (await waitTxWithToast(gameContract.attack(
        attackedTeam.address,
        "0x48656c6c6f20576f726c64210000000000000000000000000000000000000000",
        false,
        amountOutMin,
        {value: utils.parseEther(price).toString()}))) {
        // After attack
        await unclaimedBalance.refetch()
        console.log(amountOutMin)
      }
    } catch (e: any) {
      if (e) {
        alert(e)
      }
    }
  }

  useContractEvent({
    address: AttackBonusABI.address,
    abi: ['event Attack(address indexed player, address indexed team, uint256 damage, uint256 amount)'],
    eventName: 'Attack',
    listener(player, team, damage, amount) {
      try {
        console.log(player, team, damage, amount)
        if (team != ownTeam.address && team != attackedTeam.address) {
          // Not related to current teams
          return
        }
        const target = team == ownTeam.address ? attackerEl : victimEl
        showDamage(target, parseInt(utils.formatUnits(damage as BigNumber, 15)).toString(), Math.floor(Math.random() * 10))
      } catch (e) {
        console.log(player, team, damage, amount)
      }
    },
  })

  return (
    <div className='page attack-page'>
      <div className='page-content'>
        <div className='page-title'>
          Attack {teamNames[attackedTeam.address]}
          <div
            className='attack-back-btn btn-sec-empty btn-sec-red'
            onClick={() => { setAttackedTeam(undefined) }}
          >
            <div className='btn-sec-empty-content'>
              <img src='/content/img/icons/chevron-left.png' />
              <span className='attack-back-btn-txt'>Back</span>
            </div>
          </div>
        </div>
        <div className='attack-screen'>
          <div className='attacker attacker-left' ref={attackerEl}>
            <img className='attacker-img' src={ REACT_APP_NETWORK == 'testnet' ? "/content/img/teams/team1.png" : 
              "https://raw.githubusercontent.com/BoggedFinance/Bogged-Token-List/main/bsc/assets/"+ownTeam.address+"/logo.png"
              } 
              onError={(e) => (e.currentTarget.src = getLogo(ownTeam.address))}
              />
            <div className='attacker-title'>{teamNames[ownTeam.address]}</div>
            <div className='attacker-dmg'>{ownTeamDamage}</div>
            <div className='attacker-dmg-txt'>Damage Received</div>
            <div className='attacker-bg radial-light radial-light-red'></div>
          </div>
          <div className='attacker attacker-right' ref={victimEl}>
            <img className='attacker-img' src={ REACT_APP_NETWORK == 'testnet' ? "/content/img/teams/team2.png" : 
              "https://raw.githubusercontent.com/BoggedFinance/Bogged-Token-List/main/bsc/assets/"+attackedTeam.address+"/logo.png"
              } 
              onError={(e) => (e.currentTarget.src = getLogo(attackedTeam.address))}
              />
            <div className='attacker-title'>{teamNames[attackedTeam.address]}</div>
            <div className='attacker-dmg'>{attackedTeamDamage}</div>
            <div className='attacker-dmg-txt'>Damage Received</div>
            <div className='attacker-bg radial-light radial-light-blue'></div>
          </div>
          <img className='attack-screen-bg' src='/content/img/attack/screen-bg.png'/>
        </div>
        <div className='attack-options'>
          {cards.map((c) => 
          <div key={c.tier} className='attack-option card card-blue'>
          <div className='attack-option-content'>
            <div className='attack-option-prop'>
              <div className='attack-option-prop-name'>Attack Tier</div>
              <div className='attack-option-prop-divider'></div>
              <div className='attack-value'>{c.tier}</div>
            </div>
            <div className='attack-option-prop'>
              <div className='attack-option-prop-name'>Attack Damage</div>
              <div className='attack-option-prop-divider'></div>
              <div className='attack-value'>{c.points} <span className='attack-multiplier'>x{boostMultiplier}</span></div>
            </div>
            <div className='attack-option-prop'>
              <div className='attack-option-prop-name'>Attack Cost</div>
              <div className='attack-option-prop-divider'></div>
              <div className='attack-value'>{c.price} BNB</div>
            </div>
            <div className='attack-option-prop'>
              <div className='attack-option-prop-name'>Common NFTs</div>
              <div className='attack-option-prop-divider'></div>
              <div className='attack-value'>{c.commonDesc}</div>
            </div>
            <div className='attack-option-prop'>
              <div className='attack-option-prop-name'>Rare NFT Chance</div>
              <div className='attack-option-prop-divider'></div>
              <div className='attack-value'>{c.rareChanceDesc}</div>
            </div>
            <div className='attack-option-prop'>
              <div className='attack-option-prop-name'>XP Earned</div>
              <div className='attack-option-prop-divider'></div>
              <div className='attack-value'>{c.price}</div>
            </div>
            <div className='attack-option-btn btn-primary btn-primary-red' onClick={() => attack(c.price)}>
              <div className='btn-primary-content'>Attack</div>
            </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Attack
