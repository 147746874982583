
import { ChainId, Fetcher, Token, WETH, Route, Trade, TradeType, TokenAmount, Percent } from '@pancakeswap/sdk'
import { utils } from "ethers"
import { Web3Provider, BaseProvider } from '@ethersproject/providers'

const chainId = ChainId.MAINNET

const slippage = {
    "0xCd2eCd5e06b1a330789B30e8AdA3D11c51503a71": '1600'
}

function tokenData(address: string) {
    const decimals = 18
    let slippage = '1'
    switch (address.toLowerCase()) {
        case "0xCd2eCd5e06b1a330789B30e8AdA3D11c51503a71".toLowerCase(): // Piggy
            slippage = '16'
            break
        case "0x17FAbAF66256fb40F350576bafA1807429708E34".toLowerCase(): // Rocket
            slippage = '16'
            break
        case "0x8A5d7FCD4c90421d21d30fCC4435948aC3618B2f".toLowerCase(): // Monsta
            slippage = '6'
            break
        case "0x324E8E649A6A3dF817F97CdDBED2b746b62553dD".toLowerCase(): // XUSD
            slippage = '1'
            break
        case "0xF14D3692B0055Db9Ca4c04065165d59B87E763f1".toLowerCase(): // Meta
            slippage = '13'
            break
        case "0x2f499c6DA2C84063BB7e0CB1C478687210cDB615".toLowerCase(): // Reflex
            slippage = '16'
    }
    return {
        slippageTolerance: new Percent(slippage, '100'),
        token: new Token(chainId, address, decimals)
    }
}

export async function getAmountOutMin(amount: string, tokenAddress: string, provider: BaseProvider){
    const data = tokenData(tokenAddress)

    const pair = await Fetcher.fetchPairData(data.token, WETH[chainId], provider)
    const route = new Route([pair], WETH[chainId])
    const trade = new Trade(route, 
        new TokenAmount(
            WETH[chainId],
            utils.parseEther(amount).toString()),
        TradeType.EXACT_INPUT)

    // Transaction parameters
    const amountOutMin = trade.minimumAmountOut(data.slippageTolerance).raw.toString(10)
    return amountOutMin
}