import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'

import 'swiper/css'

import './carousel.scss'

const Carousel = () => {
  return (
    <div className='carousel'>
      <div className='carousel-control carousel-control-prev'>
        <img src='/content/img/icons/chevron-left.png'/>
      </div>
      <div className='carousel-items-wrapper'>
        <Swiper
          slidesPerView={4}
          slidesPerGroup={4}
          spaceBetween={10}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          breakpoints={{
            768: {
              slidesPerView: 6,
              slidesPerGroup: 6,
              spaceBetween: 20
            }
          }}
          navigation={{
            prevEl: '.carousel-control-prev',
            nextEl: '.carousel-control-next'
          }}
          modules={[Autoplay, Navigation]}
        >
          <SwiperSlide><img src='/content/img/tokens/1.png'/></SwiperSlide>
          <SwiperSlide><img src='/content/img/teams/xusd.webp'/></SwiperSlide>
          <SwiperSlide><img src='/content/img/tokens/2.png'/></SwiperSlide>
          <SwiperSlide><img src='/content/img/teams/rocket.png'/></SwiperSlide>
          <SwiperSlide><img src='/content/img/teams/reflex.png'/></SwiperSlide>
          <SwiperSlide><img src='/content/img/teams/mbusd.png'/></SwiperSlide>
          <SwiperSlide><img src='/content/img/teams/glowv2.png'/></SwiperSlide>
        </Swiper>
      </div>
      <div className='carousel-control carousel-control-next'>
        <img src='/content/img/icons/chevron-right.png'/>
      </div>
    </div>
  )
}

export default Carousel
