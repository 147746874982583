import { BigNumber, utils } from 'ethers'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useContractRead } from 'wagmi'
import { gameABI } from '../../../services/app/volumewars'
import { bigNumberFormatter } from '../../../services/misc'

import Carousel from './carousel'

import './styles.scss'

const Home = () => {
  const totalVolume = useContractRead({
    ...gameABI,
    functionName: 'totalVolume'
  })
  const volume = useMemo(() => {
    if (!totalVolume.data) {
      return 0
    }
    if (BigNumber.isBigNumber(totalVolume.data)) {
      return parseFloat(utils.formatEther(totalVolume.data))
    }
    return 0
  }, [totalVolume.data])

  return (
    <div className='page home-page'>
      <div className='page-content'>
        <div className='home-page-content'>
          <div className='main-block'>
            <div className='main-block-title'>
              <div className='main-block-title-txt1'>Welcome to</div>
              <div className='main-block-title-txt2'>Volume Wars</div>
            </div>
            <div className='main-block-txt'>Challenge your friends and foes to a fun and fair game of volume wars with community selected projects.</div>
            <div className='main-block-btns'>
            <Link to='/game' className='main-block-btn btn-sec-fill btn-sec-red'>Enter App</Link>
            <a href='https://docs.volumewars.com/' target='_blank' rel='noopener noreferrer' className='main-block-btn btn-sec-fill btn-sec-blue'>Read More</a>
            </div>
          </div>
          <Carousel />
          <div className='main-stats'>
          <div className='main-stat card card-gold'>
              <div className='main-stat-content card-content'>
                <div className='main-stat-number'>
                  <span className='main-stat-value'>{bigNumberFormatter(volume)}</span>
                  <span className='main-stat-sign'>BNB</span>
                </div>
                <div>
                  <div className='main-stat-title'>Total Volume</div>
                  <div className='main-stat-txt'>Total BNB spent on Volume Wars across all seasons</div>
                </div>
              </div>
            </div>
            <div className='main-stat card card-red'>
              <div className='main-stat-content card-content'>
                <div className='main-stat-number'>
                  <span className='main-stat-value'>{bigNumberFormatter(volume*0.5)}</span>
                  <span className='main-stat-sign'>BNB</span>
                </div>
                <div>
                  <div className='main-stat-title'>Burned Tokens</div>
                  <div className='main-stat-txt'>Total amount of tokens burned represented in BNB value from all projects featured on Volume Wars</div>
                </div>
              </div>
            </div>
            <div className='main-stat card card-blue'>
              <div className='main-stat-content card-content'>
                <div className='main-stat-number'>
                  <span className='main-stat-value'>{bigNumberFormatter(volume*0.25)}</span>
                  <span className='main-stat-sign'>BNB</span>
                </div>
                <div>
                  <div className='main-stat-title'>Legendary Rewards</div>
                  <div className='main-stat-txt'>Total rewards generated for Legendary NFT holders</div>
                </div>
              </div>
            </div>
            <div className='main-stat card card-grey'>
              <div className='main-stat-content card-content'>
                <div className='main-stat-number'>
                  <span className='main-stat-value'>{bigNumberFormatter(volume*0.15)}</span>
                  <span className='main-stat-sign'>BNB</span>
                </div>
                <div>
                  <div className='main-stat-title'>Winning Team Rewards</div>
                  <div className='main-stat-txt'>Total rewards for winning teams in BNB</div>
                </div>
              </div>
            </div>
          </div>
          <div className='main-page-audit-mob'>
            <div className='main-page-audit-txt'>Audit By:</div>
            <img src='/content/img/home/certik-mob.png'/>
          </div>
        </div>
        <div className='main-page-coins-wrapper'>
          <div className='main-page-coins'>
            <div className='main-page-light-blue radial-light radial-light-blue'></div>
            <div className='main-page-light-red radial-light radial-light-red'></div>
            <img className='main-page-coins-img' src='/content/img/home/coins.png'/>
            <img className='main-page-coins-img-mob' src='/content/img/home/coins-mob.png'/>
          </div>
        </div>
        <a href="https://www.certik.com/projects/volume-wars" target="_blank" className='main-page-audit'>
          <div className='main-page-audit-txt'>Audit By:</div>
          <img src='/content/img/home/certik.png'/>
        </a>
      </div>
    </div>
  )
}

export default Home
