import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'

import { getCountdownData, formatNumberToString } from '../../../../services/misc'

import './countdown.scss'

type Props = {
  dueTime: number
}

const MultiplierCountdown: React.FC<Props> = ({ dueTime }) => {
  const [countdownDue, setCountdownDue] = useState<Moment | null>(moment().add(dueTime, 'seconds'))
  const [countdownData, setCountdownData] = useState(getCountdownData(countdownDue))

  useEffect(() => {
    const timerFunc = () => {
      if (countdownDue) {
        if (moment() < countdownDue) {
          setCountdownData(getCountdownData(countdownDue))
        } else {
          setCountdownDue(null)
        }
      }
    }
    const timer = setInterval(timerFunc, 1000)
    return () => { clearTimeout(timer) }
  })

  return (
    <>
      { countdownDue &&
        <div className='multiplier-countdown-clock-content card-content'>
          <div className='multiplier-countdown-hours'>
            <span className='multiplier-countdown-value'>{formatNumberToString(countdownData.hours, 2)}</span>
            <span className='multiplier-countdown-sign'>H</span>
          </div>
          <div className='multiplier-countdown-mins'>
            <span className='multiplier-countdown-value'>{formatNumberToString(countdownData.minutes, 2)}</span>
            <span className='multiplier-countdown-sign'>M</span>
          </div>
          <div className='multiplier-countdown-secs'>
            <span className='multiplier-countdown-value'>{formatNumberToString(countdownData.seconds, 2)}</span>
            <span className='multiplier-countdown-sign'>S</span>
          </div>
        </div>
      }
    </>
  )
}

export default MultiplierCountdown
