import React from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import { useConnect, useAccount, useDisconnect } from 'wagmi'
import useAppState from '../../../services/appState'
import './styles.scss'

const { REACT_APP_NETWORK } = process.env

const ConnectModal = () => {
  const isConnectModalOpened = useAppState(state => state.isConnectModalOpened)
  const setIsConnectModalOpened = useAppState(state => state.setIsConnectModalOpened)

  const { connect, connectors, error, pendingConnector } = useConnect()
  const account = useAccount()
  const { disconnect } = useDisconnect()

  function connectTo(id: string) {
    connect({
      chainId: REACT_APP_NETWORK === "testnet" ? 97 : 56,
      connector: connectors.find((c) => c.id === id)
    })
  }

  if (!account.isConnected)
    return (
      <>
        <Modal
          open={isConnectModalOpened}
          BackdropComponent={Backdrop}
          onClose={() => { setIsConnectModalOpened(false) }}
          className='connect-modal modal'
        >
          <div className='modal-content card card-blue'>
            <div className='card-content'>
              <div className='connect-title'>Connect wallet</div>
              <div className='connect-btn btn-sec-empty btn-sec-red metamask'>

                <div className='btn-sec-empty-content' onClick={() => connectTo("metaMask")}>
                  <img src='/content/img/icons/metamask.png' />
                  <span>
                    Metamask
                  </span>
                </div>

              </div>


              <div className='connect-btn btn-sec-empty btn-sec-red walletconnect'>
                <div className='btn-sec-empty-content' onClick={() => connectTo("walletConnect")}>
                  <img src='/content/img/icons/walletconnect.png' />
                  <span>WalletConnect</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  else
    return (
      <>
        <Modal
          open={isConnectModalOpened}
          BackdropComponent={Backdrop}
          onClose={() => { setIsConnectModalOpened(false) }}
          className='connect-modal modal'
        >
          <div className='modal-content card card-blue'>
            <div className='card-content'>
              <div className='connect-btn btn-sec-empty btn-sec-red metamask'>
                <div className='btn-sec-empty-content' onClick={() => disconnect()}>
                  <img src={`/content/img/icons/${account.connector?.name == 'MetaMask' ? 'metamask' : 'walletconnect'}.png`} />
                    <span>
                      Disconnect
                    </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
}

export default ConnectModal
