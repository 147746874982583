import React, { useEffect, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

import useAppState from '../../../services/appState'

import './styles.scss'
import { NFT } from '../../../services/app/interfaces'
import { BigNumber, ethers, utils } from 'ethers'
import { useAccount, useContract, useContractRead, useSigner } from 'wagmi'
import { marketplaceABI, nftABI } from '../../../services/app/volumewars'
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'

const changeRate = (value: number, rate: number) => {
  return +value * +rate
}

const SellModal = () => {
  const sellModalNFT = useAppState(state => state.sellModalNFT) as NFT
  const setSellModalNFT = useAppState(state => state.setSellModalNFT)
  const [enableBuy, setEnableBuy] = useState(true)
  const [approve, setApprove] = useState(false)
  
  const [price, setPrice] = useState('1')
  const [profit, setProfit] = useState(0.85)

  const account = useAccount()
  const signer = useSigner()

  const nftContract: ethers.Contract = useContract({
    ...nftABI,  signerOrProvider: signer.data
  })!
  const marketContract: ethers.Contract = useContract({
    ...marketplaceABI,  signerOrProvider: signer.data
  })!

  const isApprovedForAll = useContractRead({
    ...nftABI,
    functionName: 'isApprovedForAll',
    args: [account.address, marketplaceABI.address] 
  })
  const marketplaceFee = useContractRead({
    ...marketplaceABI,
    functionName: 'getFeePercentage'
  })

  const approveForAll = async () => {
    if (!await checkChainId(nftContract)) {
      return
    }
    if (await waitTxWithToast(nftContract.setApprovalForAll(marketplaceABI.address, true))) {
      await isApprovedForAll.refetch()
    }
  }
  const sellCard = async () => {
    if (!await checkChainId(marketContract)) {
      return
    }
    setEnableBuy(false)
    if (await waitTxWithToast(marketContract.submitListing(sellModalNFT.id, utils.parseEther(price)))) {
      setSellModalNFT(false)
    }
    setEnableBuy(true)
  }
  const conversionRate = useMemo(() => {
    if (!marketplaceFee.data || !BigNumber.isBigNumber(marketplaceFee.data)) {
      return 0.85
    }
    const feePercentage = marketplaceFee.data as any as BigNumber
    return (1-(feePercentage.toNumber()/100))
  }, [marketplaceFee.data])

  const priceHandler = (value: any) => {
    setPrice(value.toString())
    setProfit(changeRate(value, conversionRate))
  }
  const profitHandler = (value: any) => {
    setPrice(changeRate(value, 1/conversionRate).toString())
    setProfit(value)
  }
  useEffect(() => {
    setApprove(isApprovedForAll.data as any)
  }, [isApprovedForAll.data])

  return (
    <>
      {sellModalNFT && <Modal
        open={!!sellModalNFT}
        BackdropComponent={Backdrop}
        onClose={() => { setSellModalNFT(false) }}
        className='sell-modal modal'
      >
        <div className='modal-content card card-blue'>
          <div className='card-content'>
            <div className='sell-content'>
              <video className='sell-img' src={`/content/videos/${sellModalNFT.num}.mp4`} autoPlay={true} controls={false} loop={true} playsInline={true} />
              <div className='sell-inner-content'>
                <div className='sell-title'>Sell this card</div>
                <div className='sell-txt'>
                Note: You must approve first before being able to sell<br></br><br></br>
                This is {sellModalNFT.num ? `card #${sellModalNFT.num}` : "the Legendary"} from Season {sellModalNFT.set}<br></br>
                Its unique NFT ID is {sellModalNFT.id}<br></br><br></br>

                A <b>{marketplaceFee.data && (marketplaceFee.data as any).toString()}% fee</b> is taken from the sale.<br></br><br></br>
                You can set the price on the right, to the left you see what you will receive after the fee is taken<br></br>
                </div>
                <div className='sell-exchange'>
                  <div className='sell-exchange-card card card-blue'>
                    <div className='card-content'>
                      <div className='sell-exchange-desc'>
                        <img className='sell-exchange-coin' src='/content/img/coin.png' />
                        <div>
                          <div className='sell-exchange-action'>Price</div>
                          <div className='sell-exchange-currency'>BNB</div>
                        </div>
                      </div>
                      <input className='sell-exchange-value' type='number' value={price} onChange={(v) => priceHandler(parseFloat(v.target.value))} />
                    </div>
                  </div>
                  <img src='/content/img/icons/repeat.png' />
                  <div className='sell-exchange-card card card-blue'>
                    <div className='card-content'>
                      <div className='sell-exchange-desc'>
                        <img className='sell-exchange-coin' src='/content/img/coin.png' />
                        <div>
                          <div className='sell-exchange-action'>Receive</div>
                          <div className='sell-exchange-currency'>BNB</div>
                        </div>
                      </div>
                      <input className='sell-exchange-value' type='number' value={profit} onChange={(v) => profitHandler(parseFloat(v.target.value))} />
                    </div>
                  </div>
                </div>
                <div className='sell-ctrls'>
                  <div className={`sell-ctrl-btn btn-primary btn-primary-red ${approve && 'disabled'}`} onClick={() => approveForAll()}>
                    <div className='btn-primary-content'>Approve</div>
                  </div>
                  <div className={`sell-ctrl-btn btn-primary btn-primary-blue ${(!approve || !enableBuy) && 'disabled'}`} onClick={() => sellCard()}>
                    <div className='btn-primary-content'>Sell</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img onClick={() => { setSellModalNFT(false) }} className='close-btn' src='/content/img/icons/close.png' />
        </div>
      </Modal>}
    </>
  )
}

export default SellModal
