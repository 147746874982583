import React, { useState, useEffect } from 'react'
import moment from 'moment'

import useAppState from '../../services/appState'
import { getCountdownData, formatNumberToString } from '../../services/misc'

import './countdown.scss'
import { useSeasonExpiration } from '../../services/app/seasonExpiration'

const Countdown = () => {
  const countdownDue = useAppState(state => state.countdownDue)
  const setCountdownDue = useAppState(state => state.setCountdownDue)
  const [countdownData, setCountdownData] = useState(getCountdownData(countdownDue))
  const [expirationTimestampMs, _] = useSeasonExpiration()
  useEffect(() => {
    const newExpiration = moment(expirationTimestampMs)
    if (moment() > newExpiration) { // Expired already
      setCountdownDue(null)
      return
    }
    setCountdownDue(newExpiration)
  }, [expirationTimestampMs])

  useEffect(() => {
    const timerFunc = () => {
      if (countdownDue) {
        if (moment() < countdownDue) {
          setCountdownData(getCountdownData(countdownDue))
        } else {
          setCountdownDue(null)
        }
      }
    }
    const timer = setInterval(timerFunc, 1000)
    return () => { clearTimeout(timer) }
  })

  return (
    <>
      { countdownDue &&
        <div className='header-countdown'>
          <div className='header-countdown-txt'>Season End Countown</div>
          <div className='header-countdown-clock card card-grey'>
            <div className='header-countdown-clock-content card-content'>
              <div className='header-countdown-days'>
                <span className='header-countdown-value'>{formatNumberToString(countdownData.days, 1)}</span>
                <span className='header-countdown-sign'>D</span>
              </div>
              <div className='header-countdown-hours'>
                <span className='header-countdown-value'>{formatNumberToString(countdownData.hours, 2)}</span>
                <span className='header-countdown-sign'>H</span>
              </div>
              <div className='header-countdown-mins'>
                <span className='header-countdown-value'>{formatNumberToString(countdownData.minutes, 2)}</span>
                <span className='header-countdown-sign'>M</span>
              </div>
              <div className='header-countdown-secs'>
                <span className='header-countdown-value'>{formatNumberToString(countdownData.seconds, 2)}</span>
                <span className='header-countdown-sign'>S</span>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Countdown
