import React, { useState, useEffect, useMemo } from 'react'

import { ethers } from 'ethers'
import { BigNumber } from 'ethers'
import { useContractRead, useAccount, useSigner, useContract, useProvider } from 'wagmi'

import PiggyCard from '../../shared/piggy-card'
import Dropdown from '../../shared/dropdown'
import { Listing } from '../../../services/app/interfaces'

import { gameABI, marketplaceABI } from "../../../services/app/volumewars"

import { selectId, selectMarketplaceSort } from "../../../lists/select"
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction';
import { useListingsByCard, useListingsBySeller } from "../../../services/app/cardsMarketplace"
import { formatAddress, formatBNB } from '../../../services/app/utils'

import './styles.scss'

const Marketplace = () => {
  const account = useAccount()
  const [marketplaceData, setMarketplaceData] = useState<{
    mode: 'all' | 'my'
  }>({
    mode: 'all'
  })

  const currentSeason = useContractRead({
    ...gameABI,
    functionName: 'season'
  })

  const seasonOptions = useMemo(() => {
    if (!currentSeason.data) {
      return [{value: '1', label: 'SEASON 1'}]
    }
    const output = []
    for (let i = 1; i <= (currentSeason.data as any as number); i++) output.push({
      value: i.toString(), label: `SEASON ${i}`
    })
    return output.reverse()
  }, [currentSeason.data])


  type select = { value: { value: string | number, label: string } }
  const [season, setSeason] = useState<select>({ value: { value: seasonOptions[0].value, label: seasonOptions[0].label }});
  const [id, setId] = useState<select>({ value: { value: selectId[0].value, label: selectId[0].label } });
  const [sortOption, setSortOption] = useState<select>({ value: { value: selectMarketplaceSort[0].value, label: selectMarketplaceSort[0].label } })

  const card = useMemo(() => { return { num: id.value.value as number, set: season.value.value as number } }, [id, season])

  const [listingsByCard, loadingListingsByCard, refreshByCard] = useListingsByCard(card)
  const [listingsBySeller, loadingListingsBySeller, refreshBySeller] = useListingsBySeller(account.address)

  useEffect(() => {
    if (loadingListingsBySeller) return
    refreshBySeller()
    if (!account.address) setMarketplaceData({ mode: 'all' })
  }, [account.address, loadingListingsBySeller])

  function refreshAll() {
    refreshByCard()
    refreshBySeller()
  }

  const sortedMyListing = useMemo(() => {
    const items = [...listingsBySeller]

    const sortPrice = (a: Listing, b: Listing) => {
      const reducedPrice = (l: Listing) => {
        return l.price.div(BigNumber.from("10").pow(BigNumber.from("14"))).toNumber()
      }

      return reducedPrice(b) - reducedPrice(a)
    }

    const sortRarity = (a: Listing, b: Listing) => {
      // map 0->2, [5-7]->1, [1-4]->0
      const getValue = (l: Listing) => { return l.num == 0 ? 2 : ( l.num >= 5 ? 1 : 0 ) }
      return getValue(b) - getValue(a)
    }

    const sortChrono = (a: Listing, b: Listing) => { return a.listingId - b.listingId }

    const sorter: any = {
      'price': sortPrice,
      'rarity': sortRarity,
      'id': sortChrono
    }[sortOption.value.value]

    items.sort(sorter)

    return items
  }, [listingsBySeller, sortOption.value.value])

  return (
    <div className='page marketplace-page'>
      <div className='page-content'>
        <div className='page-title'>Marketplace</div>
        <div className='marketplace-filters'>
          { marketplaceData.mode === 'all'
            ? <>
                <Dropdown
                  className='marketplace-filter season-filter'
                  options={seasonOptions}
                  placeholder={season.value.label}
                  onChange={(value) => { setSeason({ value }) }}
                />
                <Dropdown
                  className='marketplace-filter id-filter'
                  options={selectId}
                  placeholder={id.value.label}
                  value={2}
                  onChange={(value) => { setId({ value }) }}
                />
              </>
            : <Dropdown
                className='marketplace-filter sorting'
                options={selectMarketplaceSort}
                placeholder={sortOption.value.label}
                onChange={(value) => { setSortOption({ value }) }}
              />
          }
        { account.address &&
        (<>
          <div className='mode-btn btn-primary btn-primary-red'>
                <div
                  className='btn-primary-content'
                  onClick={() => {
                    if (marketplaceData.mode === 'all') {
                      setMarketplaceData({ mode: 'my' })
                    } else {
                      setMarketplaceData({ mode: 'all' })
                    }
                  }}
                >Go to {marketplaceData.mode === 'all' ? 'my' : 'all'} listings</div>
              </div>
          </>)}
        </div>
        <div className='marketplace-list'>
          {(marketplaceData.mode === 'all' ? listingsByCard : sortedMyListing).map((c) => (
            <OfferCard
              listingId={c.listingId}
              set={c.set}
              num={c.num}
              enabled={c.enabled}
              price={c.price}
              refreshCards={refreshAll}
              mode={marketplaceData.mode}
              key={c.listingId}
            />
          ))}
          { (marketplaceData.mode === 'all' ? listingsByCard : sortedMyListing).length === 0 &&
            <div className='zero-items-msg'>
              <div className='zero-items-title'>No Listings</div>
              <div className='zero-items-txt'>There are no listings for {card.num == 0 ? 'Legendary' : '#'+card.num} from Season {card.set}<br />Please select a different card.</div>
            </div>
          }
        </div>
      </div>
      <div className='page-light radial-light radial-light-blue'></div>
      <div className='page-light radial-light radial-light-red'></div>
    </div>
  )
}

export default Marketplace

const OfferCard = ({ listingId, set, num, enabled, refreshCards, price, mode }: {
  listingId: number,
  set: number,
  num: number,
  enabled: Boolean,
  price: BigNumber,
  refreshCards: Function,
  mode: string

}) => {

  const { data: signer, isError, isLoading } = useSigner()

  const marketContract: ethers.Contract = useContract({
    ...marketplaceABI, signerOrProvider: signer
  })!

  const [sellerAddress, setSellerAddress] = useState('')
  const listingInfo = useContractRead({
    ...marketplaceABI,
    functionName: 'getListing',
    args: [listingId] 
  })

  useEffect(() => {
    if (!listingInfo.data) {
      return
    }
    const dataArr = listingInfo.data as any[]
    setSellerAddress(dataArr[2])
  }, [listingInfo.data])

  async function cancel() {
    if (!await checkChainId(marketContract)) {
      return
    }
    if (await waitTxWithToast(marketContract.cancelListing(listingId))) {
      refreshCards()
    }
  }

  async function buy() {
    if (!await checkChainId(marketContract)) {
      return
    }
    if (await waitTxWithToast(marketContract.buyListing(listingId, {value: price}))) {
      refreshCards()
    }
  }

  const type = useMemo(() => {
    if (num == 0) return 'legendary'
    if (num >= 5) return 'rare'
    return 'common'
  }, [num])

  return (<>
    <PiggyCard className='marketplace-item' season={set} type={type} num={num} key={listingId}>
      <div className='marketplace-item-title'>{`${type} #${num}`}</div>
      <div className='marketplace-item-txt'>{mode === 'all' ?
                                             formatAddress(sellerAddress)
                                           : (enabled ? 'For Sale' : 'Sold')}</div>
      <div className='marketplace-card-action card card-blue'>
        <div className='marketplace-card-action-content card-content'>
          <div className='marketplace-card-action-price'>{formatBNB((price as BigNumber).toString(), 2)} BNB</div>
          
            { mode === 'my' && enabled && 
            <div className='marketplace-card-action-btn btn-primary btn-primary-red' onClick={() => { cancel() }}>
              <div className='btn-primary-content'>Cancel</div>
            </div> }
            { mode === 'all' &&
            <div className='marketplace-card-action-btn btn-primary btn-primary-red' onClick={() => { buy() }}>
              <div className='btn-primary-content'>Buy</div>
            </div>
            }
        </div>
      </div>
    </PiggyCard>
  </>)
}
