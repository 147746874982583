import { create } from 'zustand'

import { Moment } from 'moment'
import { NFT, Team } from './app/interfaces'

interface IAppState {
  isAppLoading: boolean
  setIsAppLoading: (newValue: boolean) => void
  countdownDue: Moment | null
  setCountdownDue: (newValue: Moment | null) => void
  isMobileMenuOpened: boolean
  setIsMobileMenuOpened: (newValue: boolean) => void
  isConnectModalOpened: boolean
  setIsConnectModalOpened: (newValue: boolean) => void
  attackedTeam: Team | undefined
  setAttackedTeam: (newValue: Team | undefined) => void
  ownTeam: Team | undefined
  setOwnTeam: (newValue: Team | undefined) => void
  teamNames: {[addr: string]: string | undefined}
  setTeamName: (address: string, newValue: string) => void
  setSendModalNFT: (newValue: NFT | false | undefined) => void
  sendModalNFT: NFT | false | undefined
  setSellModalNFT: (newValue: NFT | false | undefined) => void
  sellModalNFT: NFT | false | undefined

  setMintNameModal: (newValue: boolean) => void,
  mintNameModal: boolean,

  setGameOpen: (newValue: boolean) => void
  gameOpen: boolean

  setSeasonExpired: (newValue: boolean) => void
  seasonExpired: boolean
}

const useAppState = create<IAppState>(set => ({
  isAppLoading: false,
  setIsAppLoading: (newValue: boolean) => set({ isAppLoading: newValue }),
  countdownDue: null,
  setCountdownDue: (newValue: Moment | null) => set({ countdownDue: newValue }),
  isMobileMenuOpened: false,
  setIsMobileMenuOpened: (newValue: boolean) => set({ isMobileMenuOpened: newValue }),
  isConnectModalOpened: false,
  setIsConnectModalOpened: (newValue: boolean) => set({ isConnectModalOpened: newValue }),
  attackedTeam: undefined,
  setAttackedTeam: (newValue: Team | undefined) => set({ attackedTeam: newValue }),
  ownTeam: undefined,
  setOwnTeam: (newValue: Team | undefined) => set({ ownTeam: newValue }),
  teamNames: {},
  setTeamName: (address: string, newValue: string) => set((s) => (
    {...s,
      teamNames: {
        ...s.teamNames,
        [address]: newValue
      }})),
  setSendModalNFT: (newValue: NFT | false | undefined) => set({ sendModalNFT: newValue }),
  sendModalNFT:  undefined,
  setMintNameModal: (newValue: boolean) => set({ mintNameModal: newValue }),
  mintNameModal:  false,
  setSellModalNFT: (newValue: NFT | false | undefined) => set({ sellModalNFT: newValue }),
  sellModalNFT:  undefined,
  
  setGameOpen: (newValue: boolean) => set({ gameOpen: newValue }),
  gameOpen:  false,
  setSeasonExpired: (newValue: boolean) => set({ seasonExpired: newValue }),
  seasonExpired:  false,
}))

export default useAppState
