import { BigNumber } from 'ethers'
import { useState, useEffect, useMemo } from 'react'
import { useContractRead } from 'wagmi'
import { gameABI } from './volumewars'

export function useSeasonExpiration() : [number, any] {
    const [expirationTimestampMs, setExpirationTimestampMs] = useState(0)
    const seasonExpiration = useContractRead({
    ...gameABI,
    functionName: 'seasonExpiration'
  })
    
    useEffect(() => {
        if (!BigNumber.isBigNumber(seasonExpiration.data)){
          return
        }
        setExpirationTimestampMs((seasonExpiration.data as BigNumber).toNumber() * 1000)
      }, [seasonExpiration.data])

    return [expirationTimestampMs, seasonExpiration.refetch]
}

export function useSeasonExpired(): [boolean, any] {
    const [expirationTimestampMs, refetch] = useSeasonExpiration()
    const [expired, setExpired] = useState(false)

    useEffect(() => {
        const intervalId = setInterval(() => {
          setExpired(expirationTimestampMs < Date.now())
        }, 1000);
        return () => clearInterval(intervalId)
    }, [expirationTimestampMs])

    return [expired, refetch]
}