export const selectSeason = [
  {
    value: 'Choose season', label: 'Pick season'
  },
  {
    value: '1', label: 'SEASON 1'
  },
  {
    value: '2', label: 'SEASON 2'
  },
  {
    value: '3', label: 'SEASON 3'
  },
  {
    value: '4', label: 'SEASON 4'
  },
  {
    value: '5', label: 'SEASON 5'
  },
  {
    value: '6', label: 'SEASON 6'
  },
  {
    value: '7', label: 'SEASON 7'
  },
];

export const selectSeasonMarketplace = [
  {
    value: '1', label: 'SEASON 1'
  },
  {
    value: '2', label: 'SEASON 2'
  },
  {
    value: '3', label: 'SEASON 3'
  },
  {
    value: '4', label: 'SEASON 4'
  },
  {
    value: '5', label: 'SEASON 5'
  },
  {
    value: '6', label: 'SEASON 6'
  },
  {
    value: '7', label: 'SEASON 7'
  },
];

export const selectId = [
  {
    value: '5', label: 'Rare #5'
  },
  {
    value: '6', label: 'Rare #6',
  },
  {
    value: '7', label: 'Rare #7'
  },
  {
    value: '0', label: 'Legendary'
  },
];

export const selectMarketplaceSort = [
  { label: 'Sort by Price', value: 'price' },
  { label: 'Sort by Rarity', value: 'rarity' },
  { label: 'Chronological', value: 'id' }
]
