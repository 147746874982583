import { BigNumber } from "ethers"
import { NFT } from "./interfaces"

export function getLegendaryIngredients(seasonCards: {[num: number]: NFT[]}): NFT[] | false {
    if (!seasonCards) {
      return false
    }
    if(Object.keys(seasonCards).length < 7) {
      return false
    }
    const ingredients: NFT[] = []
    for (let i = 1; i <= 7; i++) {
      if (!seasonCards[i] || seasonCards[i].length == 0) {
        return false
      }
      ingredients.push(seasonCards[i][0])
    }
    return ingredients.sort((a,b) => (a.num - b.num))
}

export function toHex(s: any) {
  if (!s) return ''
  return `0x${s.toString(16)}`
}

export function formatAddress(addr: string) {
  return `${addr.slice(0,6)}...${addr.slice(-6)}`
}

export function formatTeamAddress(addr: string, nameMap: any) {
  return (nameMap && nameMap[addr.toLowerCase()]) || formatAddress(addr)
}

export function formatBNB(amount: number | string, digits: number) {
  const inEth = (amount as number) / 1e18
  return inEth.toFixed(digits)
}

export function getLogo(address: string) {
  switch (address.toLowerCase()) {
    case "0x11F331c62AB3cA958c5212d21f332a81c66F06e7".toLowerCase():
      return "/content/img/teams/team4.png"
    case "0x324E8E649A6A3dF817F97CdDBED2b746b62553dD".toLowerCase():
      return "/content/img/teams/xusd.webp"
    case "0xf14d3692b0055db9ca4c04065165d59b87e763f1".toLowerCase():
      return "/content/img/teams/mbusd.png"
    case "0x2f499c6DA2C84063BB7e0CB1C478687210cDB615".toLowerCase():
      return "/content/img/teams/reflex.png"
    case "0x05De1dbFCdc876F790371508b97C337640Dcd6a9".toLowerCase():
      return "/content/img/teams/glowv2logo.png"
    default:
      return "/content/img/header-logo.png"
  }
}

export function stringToBytes32(str: string) {
  let utf8Encode = new TextEncoder()
  const encoded = utf8Encode.encode(str).slice(0, 32)
  return encoded
}

export function bigNumberToString(bn: BigNumber) {
  let utf8Decode = new TextDecoder()
  const byteArray = Uint8Array.from(Buffer.from(bn.toHexString().slice(2), 'hex'))
  return utf8Decode.decode(byteArray)
}