import { useEffect, useState, useMemo } from 'react';

import { createClient } from 'urql';
import { useContractRead, useToken } from 'wagmi'

import { BigNumber } from 'ethers'

import { gameABI, NameRegistryABI, TokenDataABI } from '../../../services/app/volumewars'
import { toHex, formatAddress, formatBNB, bigNumberToString } from '../../../services/app/utils'
import { readContracts } from '@wagmi/core'

import './styles.scss'
import { NavLink, useParams } from 'react-router-dom';

const decimals = 2
const QURL = "https://api.thegraph.com/subgraphs/name/piggysmith/vwars4-stats"

interface leaderboardData {
  bottomTeams: { id: string, exp: string }[],
  metadata: { exp: string, numLegendary: number, numNFT: number },
  seasonData: { exp: string, id: string, numLegendary: number, numNFT: number },
  seasons: { id: string, exp: string, numNFT: number, numLegendary: number }[],
  topPlayers: { exp: string, player: { id: string } }[],
  topPlayersSeason: { exp: string, player: { id: string } }[],
  topTeams: { exp: string, id: string, players: { id: string } }[],
  topTeamsSeason: { exp: string, team: { id: string } }[]
}

function getQuery(season: string, team: string, numResults: number) {
  const query = `{
      topPlayers: players(first:${numResults}, orderBy: exp, orderDirection: desc, where:{team:"${team}"}) { exp,  id }
  }`
  return query
}

async function fetchResults(query: string, setData: Function) {
  const client = createClient({
    url: QURL,
  });

  const data = await client.query(query).toPromise()
  setData(data.data)
}

async function populateUserNames(addresses: Array<string>, setNames: Function) {
  
  const contractCalls = addresses.map((a) => ({...NameRegistryABI, functionName: 'reverseResolve', args: [a.toLowerCase()]}))
  const namesRaw = (await readContracts({contracts: contractCalls})) as BigNumber[]
  const names = namesRaw.map(n => n.eq(0) ? null : bigNumberToString(n))
  const data: any = {}

  addresses.forEach((key: string, idx: number) => {
    data[key.toLowerCase()] = names[idx]
  })

  setNames(data)
}

const TeamLeaderboards = () => {
  const { address } = useParams()

  const numResults = 25

  const [data, setData] = useState<leaderboardData>({} as leaderboardData)
  const [userNames, setUserNames] = useState<{[key: string]: string}>({})
  const [populatedUserNames, setPopulatedUserNames] = useState(false)

  const curSeason = useContractRead({
    ...gameABI,
    functionName: 'season'
  })

  const token = useToken({
    address: address as any
  })

  useEffect(() => {
    if ((!data || !data.seasonData) && !curSeason.isLoading && curSeason.data) {
      const query = getQuery(toHex(curSeason.data), address!, numResults)
      fetchResults(query, setData)
    }
  }, [curSeason.isLoading])

  const playerAddresses = useMemo(() => {
    if (!data || !data.topPlayers || !data.topPlayersSeason) return []

    const players = data.topPlayers.map(p => ((p as any).id) as string)
    .concat(data.topPlayersSeason.map(p => p.player?.id))

    return Array.from(new Set(players).values())
  }, [data.topPlayers, data.topPlayersSeason])

  useEffect(() => {
    if (playerAddresses.length == 0) return
    if (populatedUserNames) return

    populateUserNames(playerAddresses, setUserNames)
    setPopulatedUserNames(true)

  }, [playerAddresses])

  return (
    <div className="page leaderboards-page">
    <div className="page-content">
    <div className="page-title">{token.data?.name}</div>
    <div className="page-subtitle">Team Leaderboard</div>
    {/* <div className="season-info">
    <div className="season-stats table-wrapper three-column">
    <div className="table-title">Season Rewards</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Total Volume</div>
    <div className="table-cell">Reward for Winning Team</div>
    <div className="table-cell">Reward for Legendary NFT Holders</div>
    </div>

    {data.seasonData && (
      <div className="table-row card-content">
            <div className="table-cell">{formatBNB(data.seasonData.exp, 2)} BNB</div>
            <div className="table-cell">{formatBNB((data.seasonData.exp as unknown as number) * 0.15, 2)} BNB</div>
            <div className="table-cell">{formatBNB((data.seasonData.exp as unknown as number) * 0.25, 2)} BNB</div>
      </div>
    )}
    </div>
    </div>
    </div> */}

    <div className="top-info">
    <div className="top-players table-wrapper three-column">
    <div className="table-title">Top Players</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Nr.</div>
    <div className="table-cell">Player address</div>
    <div className="table-cell">Volume</div>
    </div>

    {data?.topPlayers &&
     Object.entries(data.topPlayers).map((v: any, k: any) => (
      <NavLink to={userNames[v[1].id] ? "/profile/name/"+userNames[v[1].id] : "/profile/"+v[1].id} className="table-row card-content" key={'overallplayers' + v[1].id}>
         <div className="table-cell">{k + 1}</div>
         <div className="table-cell">
           {userNames[v[1].id] || formatAddress(v[1].id)}
         </div>
         <div className="table-cell">
           {formatBNB(v[1].exp, decimals)} BNB
         </div>
       </NavLink>
    ))}
    </div>
    </div>
    {/* <div className="top-players table-wrapper three-column">
    <div className="table-title">{`Top Players in Season ${curSeason.data}`}</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Nr.</div>
    <div className="table-cell">Player address</div>
    <div className="table-cell">Volume</div>
    </div>

    {data?.topPlayersSeason &&
     Object.entries(data.topPlayersSeason).map((v: any, k: any) => (
      <NavLink to={userNames[v[1].player.id] ? "/profile/name/"+userNames[v[1].player.id] : "/profile/"+v[1].player.id} className="table-row card-content" key={'seasonplayers' + v[1].player.id}>
         <div className="table-cell">{k + 1}</div>
         <div className="table-cell">
          
         {userNames[v[1].player.id] || formatAddress(v[1].player.id)}
         </div>
         <div className="table-cell">
           {formatBNB(v[1].exp, decimals)} BNB
         </div>
       </NavLink>
    ))}
    </div>
    </div> */}
    </div>
    </div>
    <div className="page-light radial-light radial-light-blue"></div>
    <div className="page-light radial-light radial-light-red"></div>
    </div>
  )
}

export default TeamLeaderboards
