import { useEffect } from 'react'
import moment, { Moment } from 'moment'

export const formatNumberToString = (number: number, length?: number) => {
  let numberStr = number.toString()
  if (length && numberStr.length < length) {
    for (let i = 0; i < length - numberStr.length; ++i) {
      numberStr = '0' + numberStr
    }
  }
  return numberStr
}

export const bigNumberFormatter = (num: number) => {
  if (num >= 1000000000) return (num / 1000000000).toFixed(0) + 'B'
  else if (num >= 1000000) return (num / 1000000).toFixed(0) + 'M'
  else if (num >= 100000) return num.toFixed(0)
  else if (num >= 10000) return num.toFixed(1)
  else return num.toFixed(2)
}

export const getCountdownData = (finish: Moment | null) => {
  if (!finish) return { days: 0, hours: 0, minutes: 0, seconds: 0 }

  const now = moment()
  const duration = moment.duration(finish.diff(now))

  const days = duration.days()
  duration.subtract(moment.duration(days, 'days'))

  const hours = duration.hours()
  duration.subtract(moment.duration(hours, 'hours'))

  const minutes = duration.minutes()
  duration.subtract(moment.duration(minutes, 'minutes'))

  const seconds = duration.seconds()
  duration.subtract(moment.duration(seconds, 'seconds'))

  return { days, hours, minutes, seconds }
}

export const timeout = async (milliseconds: number) => {
  return await new Promise<void>(resolve => {
    setTimeout(() => { resolve() }, milliseconds)
  })
}

export const useOnClickOutside = (ref: any, handler: Function) => {
  useEffect(
    () => {
      const listener = (event: Event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler]
  )
}
