import { ContractReceipt, ethers, Transaction } from 'ethers'
import { toast } from 'react-toastify'
const { REACT_APP_NETWORK } = process.env

const bscscanURL = (txHash: string) => REACT_APP_NETWORK === 'testnet' ? `https://testnet.bscscan.com/tx/${txHash}` : `https://bscscan.com/tx/${txHash}`

export const correctChainId = REACT_APP_NETWORK === 'testnet' ? 97 : 56

async function isChainIdCorrect(contract: ethers.Contract) {
  const network = await contract.provider.getNetwork()
  return network.chainId === correctChainId
}

export async function checkChainId(contract: ethers.Contract){
  if (await isChainIdCorrect(contract)) {
    return true
  }
  alert("Change the network to Binance Smart Chain in your wallet")
  return false
}

export async function waitTxWithToast(walletConfirmPromise: any): Promise<ContractReceipt | false> {
  try {
    toast.promise(walletConfirmPromise, {
        pending: "Waiting for wallet confirmation...",
        success: "Transaction accepted, submitting to network...",
        error: {
          render({data}){
            let reason = "unknown"
            
            if (data && data.error && data.error.data && data.error.data.message) {
              reason = data.error.data.message
              console.log(data.error.data.message)
            } else if (data) {
              reason = data
              console.log(data)
            }
            return `Tx rejected: ${reason}`
          }
        }
      })
    const tx = await walletConfirmPromise
    toast.promise(tx.wait(1), {
        pending: "Waiting for transaction confirmation...",
        success: {
            render({data}){
            let hash = "[unknown hash]"
            if (data && (data as any)["transactionHash"]) {
                hash = (data as any)["transactionHash"].toString()
            }
            return <span>Transaction confirmed: <a href={bscscanURL(hash)} target="_blank">BSCScan</a></span>
        }
        },
        error: {
            render({data}){
            let hash = "[unknown hash]"
            if (data && (data as any)["transactionHash"]) {
                hash = (data as any)["transactionHash"].toString()
            }
            return <span>Transaction failed: <a href={bscscanURL(hash)} target="_blank">BSCScan</a></span>
            }
        }
    })
    const confirmedTx = await (tx.wait(1))
    console.log(confirmedTx)
    return confirmedTx
  } catch (e: any) {
    console.log("Exception while processing transaction")
    if (e.data) {
      alert(e.data.message)
    }
    return false
  }
}