import { Routes, Route, HashRouter } from 'react-router-dom'

import useAppState from '../../services/appState'

import './styles.scss'
import 'react-toastify/dist/ReactToastify.css';

import Header from '../header'
import Footer from '../footer'
import MobileNav from '../mobile-nav'

import Home from '../pages/home'
import Locker from '../pages/locker'
import Game from '../pages/game'
import Marketplace from '../pages/marketplace'
import Leaderboards from '../pages/leaderboards'
import Burn from '../pages/burn'

import LoadingScreen from '../shared/loading-screen'
import AckModal from '../shared/ack-modal'
import NetworkModal from '../shared/network-modal'
import ConnectModal from '../shared/connect-modal'
import SendModal from '../shared/send-modal'
import SellModal from '../shared/sell-modal'
import { ToastContainer } from 'react-toastify'
import {ProfileAddress, ProfileName} from '../pages/profile';
import RegisterNameModal from '../shared/register-name-modal';
import NameLocker from '../pages/name';
import TeamLeaderboards from '../pages/team-leaderboards';

const App = () => {
  const isAppLoading = useAppState(state => state.isAppLoading)
  const countdownDue = useAppState(state => state.countdownDue)
  const isMobileMenuOpened = useAppState(state => state.isMobileMenuOpened)

  return (
    <div className={`app-root ${countdownDue ? 'countdown-on' : ''}`}>
      <HashRouter>
        <Header />

        { isMobileMenuOpened && <MobileNav /> }
        { !isAppLoading
          ? <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/locker' element={<Locker />} />
              <Route path='/locker/name' element={<NameLocker />} />
              <Route path='/game' element={<Game />} />
              <Route path='/marketplace' element={<Marketplace />} />
              <Route path='/leaderboards' element={<Leaderboards />} />
              <Route path='/leaderboards/team/:address' element={<TeamLeaderboards />} />
              <Route path='/burn' element={<Burn />} />
              <Route path='/profile/name/:name' element={<ProfileName />} />
              <Route path='/profile/:address' element={<ProfileAddress />} />
            </Routes>
          : <LoadingScreen />
        }
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Footer />
      </HashRouter>
      <div className='page-bg' style={{ backgroundImage: 'url(/content/img/bg.png)' }}></div>
      <AckModal />
      <NetworkModal />
      <ConnectModal />
      <SendModal />
      <SellModal />
      <RegisterNameModal />
    </div>
  )
}

export default App
