import React, { useEffect, useMemo, useState } from 'react'

import { NameCard } from '../../shared/piggy-card'
import useAppState from '../../../services/appState'

import './styles.scss'
import { useAccount, useContract, useContractRead, useProvider, useSigner } from 'wagmi'
import { NameRegistryABI } from '../../../services/app/volumewars'
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'
import { bigNumberToString, stringToBytes32 } from '../../../services/app/utils'
import { BigNumber } from 'ethers'

const NameLocker = () => {
  const setMintNameModal = useAppState(state => state.setMintNameModal)
  const mintNameModal = useAppState(state => state.mintNameModal) as boolean

  const signer = useSigner()
  const account = useAccount()

  const nameContract = useContract({
    ...NameRegistryABI, signerOrProvider: signer.data
  })!

  const namesRaw = useContractRead({
    ...NameRegistryABI,
    functionName: 'nameCollectionOf',
    args: [account.address] 
  })

  const reverseName = useContractRead({
    ...NameRegistryABI,
    functionName: 'reverseResolve',
    args: [account.address] 
  })

  const name = useMemo(() => {
    if (!reverseName) return null
    if (!reverseName.data) return null

    console.log(reverseName.data)

    return bigNumberToString(reverseName.data as unknown as BigNumber)
  }, [reverseName.status])

  const names = useMemo(() => {
    if (!namesRaw) return []
    if (!namesRaw.data) return []

    return (namesRaw.data as unknown as BigNumber[]).map((n) => bigNumberToString(n))
  }, [namesRaw.status])

  const setReverse = async (name: string) => {
    if (!await checkChainId(nameContract)) {
      return
    }
    if (await waitTxWithToast(nameContract.setReverseRecord(stringToBytes32(name)))) {
      await reverseName.refetch()
    }
  }

  useEffect(() => {
    if (!mintNameModal) {
      console.log("refetching")
      reverseName.refetch()
      namesRaw.refetch()
    }
  }, [mintNameModal])

  return (
    <div className='page locker-page'>
      <div className='page-content'>
        <div className='page-title'>Name Locker</div>
        <div className='page-subtitle'>Set your player name and create new ones</div>
        <div className='locker-reroll card card-blue'>
          <div className='locker-reroll-content card-content'>
            <div className='locker-reroll-txt'>Your Current Name is</div>
            <div className='locker-reroll-btn btn-primary btn-primary-blue disabled'>
              <div className='btn-primary-content'>{name || "[No Name]"}</div>
            </div>
            </div>
        </div>
        <div className='locker-reroll card card-blue'>
          <div className='locker-reroll-content card-content'>
            <div className='locker-reroll-txt'>Mint a new name NFT</div>
            <div onClick={() => { setMintNameModal(true) }} className='locker-reroll-btn btn-primary btn-primary-blue'>
              <div className='btn-primary-content'>New Name</div>
            </div>
          </div>
        </div>

        <div className='locker-blocks'>
            <div className='locker-block'>
              <div className='locker-block-header'>
                <div className='locker-block-title'>Your Name Collection</div>
              </div>
              <div className='locker-items'>
              {names
              .map((nameStr: string, i: number) => 
                <NameCard name={nameStr == name ? 'Current' : ''} key={i} className='locker-item' type='legendary'>
                <div className='locker-item-ctrls'>
                  {/* <div className={`locker-item-ctrl locker-item-stake btn-primary btn-primary-red`}>
                    <div className='btn-primary-content'>{nameStr}</div>
                  </div> */}
                  <div className={`locker-item-ctrl locker-item-stake btn-primary btn-primary-red ${nameStr == name && 'disabled'}`} onClick={() => setReverse(nameStr)}>
                    <div className='btn-primary-content'>Switch to</div>
                  </div>
                  {nameStr}
                  {/* <div className='locker-item-ctrl-row'>
                    <div onClick={() => { setMintNameModal(true) }} className='locker-item-ctrl locker-item-send btn-primary btn-primary-red'><div className='btn-primary-content'>Send</div></div>
                  </div> */}
                </div>
              </NameCard>
              )}
              </div>
            </div>
        </div>
      </div>
      <div className='page-light radial-light radial-light-blue'></div>
      <div className='page-light radial-light radial-light-red'></div>
    </div>
  )
}

export default NameLocker