import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

import useAppState from '../../../services/appState'
import { useCookies } from "react-cookie"

import './styles.scss'

const AckModal = () => {
  const [isAckAgreed, setIsAckAgreed] = useState(false)
  const [cookies, setCookie] = useCookies()
  const [viewDisclaimer, setViewDisclaimer] = useState(!cookies.hideVWDisclaimer)

  function dismissDisclaimer() {
    setViewDisclaimer(false);
    setCookie("hideVWDisclaimer", true, { sameSite: "lax" });
  }
  
  return (
    <>
      <Modal
        open={viewDisclaimer}
        BackdropComponent={Backdrop}
        className='ack-modal modal'
      >
        <div className='modal-content card card-blue'>
          <div className='card-content'>
            <div className='ack-title'>Volume Wars Disclaimer:</div>
            <div className='ack-subtitle'>Acknowledgement of Terms & Conditions of access</div>
            <div className='ack-txt'>
              <div className='ack-txt-item'>Use of the volumewars.app website, services, dapp, or application is subject to the following terms and conditions and I hereby confirm that by proceeding and interacting with the protocol I am aware of these and accept them in full:</div>
              <div className='ack-txt-item'>volumewars.app is a smart contract protocol in alpha stage of launch, and even though multiple security audits have been completed on the smart contracts, I understand the risks associated with using the Volume Wars protocol and associated functions.</div>
              <div className='ack-txt-item'>Any interactions that I have with the associated Volume Wars protocol apps, smart contracts or any related functions MAY place my funds at risk, and I hereby release the Volume Wars protocol and its contributors, team members, and service providers from any and all liability associated with my use of the above-mentioned functions.</div>
              <div className='ack-txt-item'>I am lawfully permitted to access this site and use the volumewars.app application functions, and I am not in contravention of any laws governing my jurisdiction of residence or citizenship.</div>
            </div>
            <div className='ack-agree'>
              <div
                className={`ack-tick ${isAckAgreed ? 'ack-tick-agreed' : ''}`}
                onClick={() => { setIsAckAgreed(!isAckAgreed) }}
              ><img src='/content/img/icons/tick.png' /></div>
              <span>I understand the risks involved, proceed to the app</span>
            </div>
            <div
              className={`ack-btn btn-primary btn-primary-red ${isAckAgreed ? 'ack-tick-agreed' : ''}`}
              onClick={() => { dismissDisclaimer() }}
            >
              <div className='btn-primary-content'>Proceed</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AckModal
