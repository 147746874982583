import React from 'react'

import './styles.scss'

const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <img src='/content/img/loading.gif' />
    </div>
  )
}

export default LoadingScreen
