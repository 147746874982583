import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

import Countdown from './countdown'
import useAppState from '../../services/appState'

import './styles.scss'
import { useAccount, useConnect, useContract, useContractRead, useDisconnect, useSigner } from 'wagmi'
import { gameABI } from '../../services/app/volumewars'
import { useSeasonExpired } from '../../services/app/seasonExpiration'
import { checkChainId, waitTxWithToast } from '../../services/app/transaction'
import { ethers } from 'ethers'

const { REACT_APP_NETWORK } = process.env

const Header = () => {
  const [scrollPercent, setScrollPercent] = useState(0)
  const setIsMobileMenuOpened = useAppState(state => state.setIsMobileMenuOpened)
  const setIsConnectModalOpened = useAppState(state => state.setIsConnectModalOpened)

  const handleScroll = () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    const value = scrollTop / (docHeight - winHeight)
    setScrollPercent(value || 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  const { connect, connectors } = useConnect()
  const {address: account} = useAccount()
  const { disconnect } = useDisconnect()

  function connectTo(id: string) {
    connect({
      chainId: REACT_APP_NETWORK === "testnet" ? 97 : 56,
      connector: connectors.find((c) => c.id === id)
    })
  }

  const signer = useSigner()
  const gameOpen = useContractRead({
    ...gameABI,
    functionName: 'open'
  })
  
  const season = useContractRead({
    ...gameABI,
    functionName: 'season'
  })

  const [seasonExpired, refetchExpired] = useSeasonExpired()

  const gameContract: ethers.Contract = useContract({
    ...gameABI,  signerOrProvider: signer.data
  })!

  const switchSeason = async () => {
    if (!await checkChainId(gameContract)) {
      return
    }
    if (await waitTxWithToast(gameContract.switchSeason())) {
        await gameOpen.refetch()
        await season.refetch()
        await refetchExpired()
    }
  }

  const setSeasonExpired = useAppState(state => state.setSeasonExpired)
  const setGameOpen = useAppState(state => state.setGameOpen)
  
  useEffect(() => {
    setSeasonExpired(seasonExpired)
    setGameOpen(!!gameOpen.data)
  }, [gameOpen.data, seasonExpired])

  return (
    <div className={`header-wrapper ${scrollPercent === 0 ? 'init' : ''}`}>
      <div className='header-content-wrapper'>
        <Countdown />
        <div className='header-season-mobile-wrapper'>
          <div className='header-season header-season-mobile card card-blue'>
            <div className='card-content'>
              <>
                <span>Season {gameOpen.data && !seasonExpired ? '(Open)' : '(Closed)'}</span>
                <span className='season-number'>{(season.data as any) || ""}</span>
                {gameOpen.data && seasonExpired && 
                <div className='header-season-btn btn-primary btn-primary-blue' onClick={() => switchSeason()}>
                  <div className='btn-primary-content' >Open Season</div>
                </div>}
              </>
            </div>
          </div>
        </div>
        <div className='header-content'>
          <Link to='/' className='header-logo'><img src='/content/img/header-logo.png' /></Link>
          <div className='header-season card card-blue'>
            <div className='card-content'>
              <>
              <span>Season {gameOpen.data && !seasonExpired ? '(Open)' : '(Closed)'}</span>
              <span className='season-number'>{(season.data as any) || ""}</span>
              {gameOpen.data && seasonExpired &&
              <div className='header-season-btn btn-primary btn-primary-blue' onClick={() => switchSeason()}>
                <div className='btn-primary-content'>Open Season</div>
              </div>}
              </>
            </div>
          </div>
          <div className='header-links'>
            <NavLink to='/game' className={({ isActive }) => `header-link ${isActive ? 'active' : ''}`}>Game</NavLink>
            <NavLink to='/locker' className={({ isActive }) => `header-link ${isActive ? 'active' : ''}`}>NFT Locker</NavLink>
            <NavLink to='/marketplace' className={({ isActive }) => `header-link ${isActive ? 'active' : ''}`}>Marketplace</NavLink>
            <NavLink to='/leaderboards' className={({ isActive }) => `header-link ${isActive ? 'active' : ''}`}>Leaderboards</NavLink>
            <a href='https://docs.volumewars.com/' target='_blank' rel='noopener noreferrer' className='header-link'>Docs</a>
          </div>
          {!account && 
          <div className='header-wallet-btns'>
            <div className='header-wallet-btn btn-sec-empty btn-sec-red metamask'>
              <div className='btn-sec-empty-content' onClick={() => connectTo("metaMask")}>
                <img src='/content/img/icons/metamask.png' />
                <span>Metamask</span>
              </div>
            </div>
            <div className='header-wallet-btn btn-sec-empty btn-sec-blue walletconnect'>
              <div className='btn-sec-empty-content' onClick={() => connectTo("walletConnect")}>
                <img src='/content/img/icons/walletconnect.png' />
                <span>WalletConnect</span>
              </div>
            </div>
            <div
              className='header-wallet-btn btn-sec-empty btn-sec-red modalconnect'
              onClick={() => { setIsConnectModalOpened(true) }}
            >
              <div className='btn-sec-empty-content'>
                <div>Connect Wallet</div>
              </div>
            </div>
          </div>}
          {account && 
            <div className='header-wallet-btns'>
              <div className='header-wallet-btn btn-sec-empty btn-sec-blue walletconnect'>
                <div className='btn-sec-empty-content' onClick={() => { disconnect() }}>
                  <span>Disconnect</span>
                </div>
              </div>
              <div className='header-wallet-btn btn-sec-empty btn-sec-red modalconnect' onClick={() => { disconnect() }}>
                <div className='btn-sec-empty-content'>
                  <div>Disconnect</div>
                </div>
              </div>
            </div>
          }
          <div
            className='header-mobile-btn'
            onClick={() => { setIsMobileMenuOpened(true) }}
          >
            <img src='/content/img/icons/menu.png' />
          </div>
          <div className='header-bg' style={{ backgroundImage: 'url(/content/img/bg.png)' }}></div>
        </div>
      </div>
    </div>
  )
}

export default Header
