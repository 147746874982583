import React, { useEffect, useMemo, useState } from 'react'

import useAppState from '../../../services/appState'

import Teams from './select'
import Attack from './attack'
import { useTeams } from '../../../services/app/teams'
import { useAccount, useContractRead } from 'wagmi'
import { AttackBonusABI, gameABI } from '../../../services/app/volumewars'
import { BigNumber } from 'ethers'

const Game = () => {
  const attackedTeam = useAppState(state => state.attackedTeam)
  const setAttackedTeam = useAppState(state => state.setAttackedTeam)
  const setOwnTeam = useAppState(state => state.setOwnTeam)

  const teams = useTeams()
  const account = useAccount()
  const ownTeam = useContractRead({
    ...gameABI,
    functionName: 'teamOf',
    args: [account.address]
  })
  useEffect(() => {
    if (!teams.length || !ownTeam.data || ownTeam.status != 'success'){
      return
    }
    setOwnTeam(teams.find((t) => t.address == (ownTeam.data as string | undefined)))
    if (attackedTeam) {
      setAttackedTeam(teams.find((t) => t.address == attackedTeam.address))
    }
  }, [ownTeam.data, teams])

  const seasonExpired = useAppState(state => state.seasonExpired)
  const gameOpen = useAppState(state => state.gameOpen)

  useEffect(() => {
    if (seasonExpired || !gameOpen) {
      setAttackedTeam(undefined)
    }
  }, [seasonExpired, gameOpen])

  const boostPercentage = useContractRead({
    ...AttackBonusABI,
    functionName: 'currentBoostPercentage'
  })

  const boostMultiplier = useMemo(() => {
    if (!BigNumber.isBigNumber(boostPercentage.data)) {
      return "1"
    }
    return ((boostPercentage.data as any as BigNumber).toNumber() / 100).toFixed(1)
  }, [boostPercentage.data])

  const nextBoost = useContractRead({
    ...AttackBonusABI,
    functionName: 'nextBoost'
  })
  const nextBoostData = useMemo(() => {
    if (!nextBoost.data) {
      return {boost: "1", time: 0}
    }

    return {
        boost: (((nextBoost.data as any)[0] as BigNumber).toNumber() / 100).toFixed(1),
        time: ((nextBoost.data as any)[1] as any as BigNumber).toNumber()
    }
  }, [nextBoost.data])

  return (
    <>{ attackedTeam ? <Attack boostMultiplier={boostMultiplier} /> : <Teams teams={teams} boostMultiplier={boostMultiplier} nextBoostData={nextBoostData} /> }</>
  )
}

export default Game
