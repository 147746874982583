import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

import useAppState from '../../../services/appState'

import './styles.scss'
import { NFT } from '../../../services/app/interfaces'
import { useAccount, useContract, useSigner } from 'wagmi'
import { ethers } from 'ethers'
import { nftABI } from '../../../services/app/volumewars'
import { checkChainId, waitTxWithToast } from '../../../services/app/transaction'

const SendModal = () => {
  const sendModalNFT = useAppState(state => state.sendModalNFT) as NFT
  const setSendModalNFT = useAppState(state => state.setSendModalNFT)
  const [value, setValue] = useState("")
  const onChange = (e: any) => setValue(e.target.value)
  
  const account = useAccount()
  const signer = useSigner()
  const nftContract: ethers.Contract = useContract({
    ...nftABI, signerOrProvider: signer.data
  })!

  const sendNFT = async () => {
    if (value.length == 0) {
      return
    }
    if (!await checkChainId(nftContract)) {
      return
    }
    if (await waitTxWithToast(nftContract.transferFrom(account.address, value, sendModalNFT.id))) {
      setSendModalNFT(false)
    }
  }
  
  return (
    <>
      {sendModalNFT && <Modal
        open={!!sendModalNFT}
        BackdropComponent={Backdrop}
        onClose={() => { setSendModalNFT(false) }}
        className='send-modal modal'
      >
        <div className='modal-content card card-blue'>
          <div className='card-content'>
            <div className='send-content'>
              <div className='send-info'>Unique ID: {sendModalNFT.id}<br />Season {sendModalNFT.set}
              <br />{sendModalNFT.num == 0 ? "Legendary" : 'Number '+sendModalNFT.num + (sendModalNFT.num > 4 ? " (Rare)": " (Common)")}
              </div>
              <div className='send-txt'>Remember to double check the address</div>
              <div className='send-input-wrapper'><input type='text' placeholder='Send to address' onChange={onChange}/></div>
              <div className='send-btn btn-primary btn-primary-red' onClick={() => sendNFT()}>
                <div className='btn-primary-content'>Send</div>
              </div>
            </div>
          </div>
          <img onClick={() => { setSendModalNFT(false) }} className='close-btn' src='/content/img/icons/close.png' />
        </div>
      </Modal>}
    </>
  )
}

export default SendModal
