import React, { useEffect, useState, useRef } from 'react'

import { useOnClickOutside } from '../../../services/misc'

import './styles.scss'

interface DropdownOption {
  label: string
  value: string | number
}

type Props = {
  className?: string
  onChange?: (value: DropdownOption) => void
  placeholder?: string
  options: DropdownOption[]
  value?: string | number
}

const Dropdown: React.FC<Props> = ({ className, onChange, placeholder, options, value }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))

  useEffect(() => {
    if (value) {
      const foundOption = options.find(option => option.value === value)
      if (foundOption) setSelectedOption(foundOption)
    }
  }, [])

  return (
    <div className={`dropdown-outer-wrapper ${className || ''} ${isOpen ? 'dropdown-opened' : ''}`} ref={ref}>
      <div className='dropdown-wrapper card card-blue'>
        <div className='dropdown-content card-content'>
          <div className='dropdown-placeholder' onClick={() => { setIsOpen(!isOpen) }}>
            <div className='dropdown-placeholder-value'>{selectedOption?.label || placeholder}</div>
            <img className='dropdown-toggle' src='/content/img/icons/chevron-bottom.png' />
          </div>
          { isOpen &&
              <div className='dropdown-options'>
              {
                options.map((option, i) => (
                  <div
                    className={`dropdown-option ${option.value === selectedOption?.value ? 'dropdown-option-selected' : ''}`}
                    key={i}
                    onClick={() => {
                      setSelectedOption(option)
                      if (onChange) onChange(option)
                      setIsOpen(false)
                    }}
                  >{option.label}</div>
                ))
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Dropdown
