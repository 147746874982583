import React, { useMemo } from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

import './styles.scss'
import { useNetwork, useSwitchNetwork } from 'wagmi'
import { correctChainId } from '../../../services/app/transaction'

const NetworkModal = () => {
  const network = useNetwork()
  const switchNetwork = useSwitchNetwork()
  const correctNetworkConnected = useMemo(() => {
    if (!network.chain?.id) {
      return true
    }
    if (correctChainId === network.chain?.id) {
      return true
    }
    return false
  }, [network.chain, network.chain?.id, correctChainId])

  async function switchFromWarning() {
    if (!switchNetwork.switchNetworkAsync) {
      return
    }
    if (correctNetworkConnected) {
      return
    }
    await switchNetwork.switchNetworkAsync(correctChainId)
  }
  return (
    <>
      <Modal
        open={!correctNetworkConnected}
        BackdropComponent={Backdrop}
        className='network-modal modal'
      >
        <div className='modal-content card card-red'>
          <div className='card-content'>
            <div className='network-title'>Warning: Wrong network!</div>
            <div className='network-img'><img src='/content/img/coin.png' /></div>
            <div className='network-txt'>SWITCH YOUR WALLET TO <span>Binance Smart Chain</span></div>
            <div
              className='network-btn btn-primary btn-primary-red'
              onClick={() => { switchFromWarning() }}
            >
              <div className='btn-primary-content'>Switch Network</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default NetworkModal
