import { useEffect, useState, useMemo } from 'react';

import { createClient } from 'urql';
import { useContractRead } from 'wagmi'

import { BigNumber } from 'ethers'

import { gameABI, NameRegistryABI, TokenDataABI } from '../../../services/app/volumewars'
import { toHex, formatAddress, formatBNB, bigNumberToString } from '../../../services/app/utils'
import { readContracts } from '@wagmi/core'

import './styles.scss'
import { NavLink } from 'react-router-dom';

const decimals = 2
const QURL = "https://api.thegraph.com/subgraphs/name/piggysmith/vwars4-stats"

interface leaderboardData {
  bottomTeams: { id: string, exp: string }[],
  metadata: { exp: string, numLegendary: number, numNFT: number },
  seasonData: { exp: string, id: string, numLegendary: number, numNFT: number },
  seasons: { id: string, exp: string, numNFT: number, numLegendary: number }[],
  topPlayers: { exp: string, player: { id: string } }[],
  topPlayersSeason: { exp: string, player: { id: string } }[],
  topTeams: { exp: string, id: string, players: { id: string } }[],
  topTeamsSeason: { exp: string, team: { id: string } }[]
}

function getQuery(season: string, numResults: number) {
  const query = `{
      topPlayersSeason: seasonParticipations(first:${numResults}, orderBy: exp, orderDirection: desc, where:{season:"${season}"}) { exp, player { id } }
      topPlayers: players(first:${numResults}, orderBy: exp, orderDirection: desc) { exp,  id }
      topTeams: teams(first:${numResults}, orderBy: exp, orderDirection: desc) { id, exp, players { id } }
      seasonData: season(id:"${season}") { id, winner { id }, exp, numNFT, numLegendary }
      seasons: seasons(first: ${numResults}, orderBY: exp) { id, exp, numNFT, numLegendary }
      metadata: metaData(id: "0") { numNFT, numLegendary, season, exp }
      bottomTeams: teams(first:${numResults}, orderBy: exp, orderDirection: asc) { id, exp }
      topTeamsSeason: teamParticipations(first:${numResults}, orderBy:exp, orderDirection:desc, where:{season:"${season}"}) { team { id }, exp}
  }`
  return query
}

async function fetchResults(query: string, setData: Function) {
  const client = createClient({
    url: QURL,
  });

  const data = await client.query(query).toPromise()
  setData(data.data)
}

async function populateNames(addresses: string[], setNames: Function) {

  const contractCalls = addresses.map((a) => ({...TokenDataABI, functionName: 'tokenInfo', args: [a.toLowerCase()]}))
  const names: any[] = await readContracts({contracts: contractCalls})
  
  const data: any = {}
  addresses.forEach((key: string, idx: number) => {
      data[key.toLowerCase()] = names[idx][2]
  })

  setNames(data)
}

async function populateUserNames(addresses: Array<string>, setNames: Function) {
  
  const contractCalls = addresses.map((a) => ({...NameRegistryABI, functionName: 'reverseResolve', args: [a.toLowerCase()]}))
  const namesRaw = (await readContracts({contracts: contractCalls})) as BigNumber[]
  const names = namesRaw.map(n => n.eq(0) ? null : bigNumberToString(n))
  const data: any = {}

  addresses.forEach((key: string, idx: number) => {
    data[key.toLowerCase()] = names[idx]
  })

  setNames(data)
}

const Leaderboards = () => {
  const numResults = 10

  const [data, setData] = useState<leaderboardData>({} as leaderboardData)
  const [names, setNames] = useState<any>({})
  const [userNames, setUserNames] = useState<{[key: string]: string}>({})
  const [populatedNames, setPopulatedNames] = useState(0)
  const [populatedUserNames, setPopulatedUserNames] = useState(false)

  const curSeason = useContractRead({
    ...gameABI,
    functionName: 'season'
  })

  const teamAddresses = useContractRead({
    ...gameABI,
    functionName: 'getActiveTeams'
  })

  useEffect(() => {
    if ((!data || !data.seasonData) && !curSeason.isLoading && curSeason.data) {
      const query = getQuery(toHex(curSeason.data), numResults)
      fetchResults(query, setData)
    }
  }, [curSeason.isLoading])

  const playerAddresses = useMemo(() => {
    if (!data || !data.topPlayers || !data.topPlayersSeason) return []

    const players = data.topPlayers.map(p => ((p as any).id) as string)
    .concat(data.topPlayersSeason.map(p => p.player?.id))

    return Array.from(new Set(players).values())
  }, [data.topPlayers, data.topPlayersSeason])

  useEffect(() => {
    if (playerAddresses.length == 0) return
    if (populatedUserNames) return

    populateUserNames(playerAddresses, setUserNames)
    setPopulatedUserNames(true)

  }, [playerAddresses])

  useEffect(() => {
    if (!teamAddresses.data || !data?.topTeams) return
    let otherTeams: String[] = []

    if (data.topTeams) {
      data.topTeams.forEach((team) => {
        if (!(team.id in (teamAddresses.data as any[]))) otherTeams.push(team.id)
      })
    }
    const allAddresses = (teamAddresses.data as any[]).concat(otherTeams)
    if (populatedNames >= allAddresses.length) return

    populateNames(allAddresses, setNames)
    setPopulatedNames(allAddresses.length)
  }, [teamAddresses.status, data?.topTeams])

  const topTeams = useMemo(() => {

    if (!data?.topTeams) return
    return data.topTeams.map((v: any, k: any) => ({
      exp: v.exp,
      address: v.id,
      index: k + 1,
      name: names[v.id] ? names[v.id] : v.id,
      value: `${formatBNB(v.exp, decimals)} BNB`,
      numPlayers: v.players.length
    }))
  }, [data?.topTeams, names])

  return (
    <div className="page leaderboards-page">
    <div className="page-content">
    <div className="page-title">Leaderboards</div>
    <div className="page-subtitle">The end results & detailed statistics</div>
    <div className="season-info">
    <div className="season-stats table-wrapper three-column">
    <div className="table-title">Season Rewards</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Total Volume</div>
    <div className="table-cell">Reward for Winning Team</div>
    <div className="table-cell">Reward for Legendary NFT Holders</div>
    </div>

    {data.seasonData && (
      <div className="table-row card-content">
            <div className="table-cell">{formatBNB(data.seasonData.exp, 2)} BNB</div>
            <div className="table-cell">{formatBNB((data.seasonData.exp as unknown as number) * 0.15, 2)} BNB</div>
            <div className="table-cell">{formatBNB((data.seasonData.exp as unknown as number) * 0.25, 2)} BNB</div>
      </div>
    )}
    </div>
    </div>

    <div className="season-list table-wrapper four-column">
    <div className="table-title">Seasons</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Season</div>
    <div className="table-cell">Minted NFTs</div>
    <div className="table-cell">Legendary NFTs</div>
    <div className="table-cell">Total Volume</div>
    </div>

    {data?.seasonData &&
     Object.values(data.seasons).map((e: any) => (
       <div className="table-row card-content" key={e.id}>
         <div className="table-cell">{parseInt(e.id)}</div>
         <div className="table-cell">{e.numNFT}</div>
         <div className="table-cell">{e.numLegendary}</div>
         <div className="table-cell">
           {formatBNB(e.exp, decimals)} BNB
         </div>
       </div>
    ))}
    </div>
    </div>
    </div>


    <div className="top-info">


    <div className="top-players table-wrapper three-column">
    <div className="table-title">{`Top Players in Season ${curSeason.data}`}</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Nr.</div>
    <div className="table-cell">Player address</div>
    <div className="table-cell">Volume</div>
    </div>

    {data?.topPlayersSeason &&
     Object.entries(data.topPlayersSeason).map((v: any, k: any) => (
      <NavLink to={userNames[v[1].player.id] ? "/profile/name/"+userNames[v[1].player.id] : "/profile/"+v[1].player.id} className="table-row card-content" key={'seasonplayers' + v[1].player.id}>
         <div className="table-cell">{k + 1}</div>
         <div className="table-cell">
          
         {userNames[v[1].player.id] || formatAddress(v[1].player.id)}
         </div>
         <div className="table-cell">
           {formatBNB(v[1].exp, decimals)} BNB
         </div>
       </NavLink>
    ))}
    </div>
    </div>


    <div className="top-players table-wrapper three-column">
    <div className="table-title">Top Players Overall</div>
    <div className="table card">
    <div className="table-header card-content">
    <div className="table-cell">Nr.</div>
    <div className="table-cell">Player address</div>
    <div className="table-cell">Volume</div>
    </div>

    {data?.topPlayers &&
     Object.entries(data.topPlayers).map((v: any, k: any) => (
      <NavLink to={userNames[v[1].id] ? "/profile/name/"+userNames[v[1].id] : "/profile/"+v[1].id} className="table-row card-content" key={'overallplayers' + v[1].id}>
         <div className="table-cell">{k + 1}</div>
         <div className="table-cell">
           {userNames[v[1].id] || formatAddress(v[1].id)}
         </div>
         <div className="table-cell">
           {formatBNB(v[1].exp, decimals)} BNB
         </div>
       </NavLink>
    ))}
    </div>
    </div>
    </div>
    <div className="top-info">
    <div className="top-teams table-wrapper three-column">
      <div className="table-title">{`Top Teams in Season ${curSeason.data}`}</div>
      <div className="table card">
        <div className="table-header card-content">
          <div className="table-cell">Nr.</div>
          <div className="table-cell">Team</div>
          <div className="table-cell">Volume</div>
        </div>

        {data?.topTeamsSeason &&
         data?.topTeamsSeason.map((v: any, k: any) => {
           if (v.exp > 1000) return (
            <NavLink to={`/leaderboards/team/${v.team.id}`} className="table-row card-content" key={'seasonteams' + v.team.id}>
               <div className="table-cell">{k + 1}</div>
               <div className="table-cell">{names[v.team.id] ? names[v.team.id] : formatAddress(v.team.id)}</div>
               <div className="table-cell">
                 {formatBNB(v.exp, decimals)} BNB
               </div>
             </NavLink>
        )})}
      </div>
    </div>


    <div className="top-teams table-wrapper four-column">
      <div className="table-title">Top Teams Overall</div>
      <div className="table card">
        <div className="table-header card-content">
          <div className="table-cell">Nr.</div>
          <div className="table-cell">Team</div>
          <div className="table-cell">Volume</div>
          <div className="table-cell">Players</div>
        </div>

        {topTeams &&
         topTeams.map((team) => {
           if (team.exp > 1000) return (
            <NavLink to={`/leaderboards/team/${team.address}`}  className="table-row card-content" key={'overallteams' + team.address}>
               <div className="table-cell">{team.index}</div>
               <div className="table-cell">{team.name}</div>
               <div className="table-cell">
                 {team.value}
               </div>
               <div className="table-cell">{team.numPlayers}</div>
             </NavLink>
        )})}
      </div>
    </div>
    </div>
    </div>
    <div className="page-light radial-light radial-light-blue"></div>
    <div className="page-light radial-light radial-light-red"></div>
    </div>
  )
}

export default Leaderboards
