import { ethers, utils } from 'ethers'
import React, { useEffect, useMemo } from 'react'
import { useAccount, useContract, useContractRead, useSigner } from 'wagmi'
import { Team } from '../../../../services/app/interfaces'
import { checkChainId, waitTxWithToast } from '../../../../services/app/transaction'
import { getLogo } from '../../../../services/app/utils'
import { gameABI, TokenDataABI } from '../../../../services/app/volumewars'

import MultiplierCountdown from './countdown'
import useAppState from '../../../../services/appState'

import './styles.scss'
const { REACT_APP_NETWORK } = process.env

const Game = ({teams, boostMultiplier, nextBoostData} : {boostMultiplier: string,  teams: Team[], nextBoostData: {boost: string, time: number}}) => {
  return (
    <div className='page game-page'>
      <div className='page-content'>
        <div className='page-title'>Battle for the Burns</div>
        {nextBoostData.time > 0 &&
        <div className='game-multipliers'>
          <div className='game-multiplier'>
            <div className='game-multiplier-label'>Current global damage multiplier:</div>
            <div className='game-multiplier-value x-value'>x{boostMultiplier}</div>
          </div>
          <div className='game-multiplier'>
            <div className='game-multiplier-label'>Next global damage multiplier:</div>
            <div className='game-multiplier-value x-value'>x{nextBoostData.boost}</div>
          </div>
          <div className='game-multiplier'>
            <div className='game-multiplier-label'>Time remaining until damage reduction:</div>
            <div className='game-multiplier-value'><MultiplierCountdown dueTime={nextBoostData.time} /></div>
          </div>
        </div>}
        <div className='game-teams'>
          {teams.map((team, i) => <TeamCard key={team.address} team={team} index={i+1}/>)}
        </div>
      </div>
      <div className='page-light radial-light radial-light-blue'></div>
      <div className='page-light radial-light radial-light-red'></div>
    </div>
  )
}

export default Game

const TeamCard = ({team, index}: {team: Team, index: number}) => {
  const setAttackedTeam = useAppState(state => state.setAttackedTeam)
  const setOwnTeam = useAppState(state => state.setOwnTeam)
  const ownTeam = useAppState(state => state.ownTeam)
  
  const signer = useSigner()
  const { address: account } = useAccount()

  const gameContract: ethers.Contract = useContract({
    ...gameABI,  signerOrProvider: signer.data
  })!
  const tokenInfo = useContractRead({
    ...TokenDataABI,
    functionName: 'tokenInfo',
    args: [team.address] 
  })
  const isOwnTeam = team.address == ownTeam?.address
  const teamDamage = useMemo(() => parseInt(utils.formatUnits(team.damagePoints, 15)).toString(), [team.damagePoints])
  const nameTeam = tokenInfo.data && (tokenInfo.data as any)[2]
  const setTeamName = useAppState(state => state.setTeamName)
  useEffect(() => {
    setTeamName(team.address, nameTeam)
  }, [nameTeam, team.address])

  async function join() {
    if (!await checkChainId(gameContract)) {
      return
    }
    if (await waitTxWithToast(gameContract.join(team.address))) {
      setOwnTeam(team)
    }
  }

  const seasonExpired = useAppState(state => state.seasonExpired)
  const gameOpen = useAppState(state => state.gameOpen)

  const gameAvailable = !seasonExpired && gameOpen

  return (
  <div key={team.address} className={`game-team ${ownTeam ? 'attack':'select'} card`}>
    <div className='game-team-content card-content'>
      <img className='game-team-img' src={REACT_APP_NETWORK == 'testnet' ? `/content/img/teams/team${index}.png` :
        "https://raw.githubusercontent.com/BoggedFinance/Bogged-Token-List/main/bsc/assets/"+team.address+"/logo.png"} 
        onError={(e) => (e.currentTarget.src = getLogo(team.address))}
        />
      <div className='game-team-title'>{nameTeam}</div>
      <div className='game-team-divider'></div>
      <div className='game-team-dmg-number'>{teamDamage}</div>
      <div className='game-team-dmg-txt'>Damage Received</div>
      <div className='game-team-wins'>
        <div className='game-team-wins-txt'>Victories</div>
        <div className='game-team-wins-divider'></div>
        <div className='game-team-wins-number'>{team.wins}</div>
      </div>
      {gameAvailable && ownTeam && !isOwnTeam && 
        <div className='game-team-btn btn-primary btn-primary-red' onClick={() => setAttackedTeam(team)}>
          <div className='btn-primary-content'>Attack</div>
        </div>}
      {account && gameAvailable && !ownTeam && <div className='game-team-btn btn-primary btn-primary-blue' onClick={() => join()}>
        <div className='btn-primary-content'>Join Team</div>
      </div>}
      {isOwnTeam && <div className='game-team-your'>your<br />team</div>}
      <div className='game-team-rank'><span className='game-team-rank-number'>{index}</span><br />rank</div>
    </div>
  </div>
  )
}