import { BigNumber, ethers } from 'ethers'
import { useState, useEffect, useMemo } from 'react'
import { useContractRead } from 'wagmi'
import { NFT, OrderedNFTs } from './interfaces'
import { NFTDataABI } from './volumewars'

export function useNFTCollection(address: string | undefined): [
    OrderedNFTs,
    (config?: {
        args?: any;
        overrides?: ethers.CallOverrides | undefined;
    } | undefined) => void]
{
    const [orderedCollection, setOrderedCollection] = useState<OrderedNFTs>({ "legendary": [] })

    const [collectionConfig,
           setCollectionConfig] = useState<{args?: any,
                                            overrides?: ethers.CallOverrides | undefined
                                            enabled: boolean}>({ args: address ? [address] : undefined,
                                                                 enabled: false })

    const nftCollection = useContractRead({
        ...NFTDataABI,
        functionName: 'nftCollectionOf',
        args: collectionConfig.args,
        enabled: collectionConfig.enabled
    })

    useEffect(() => {
        if (!nftCollection.data) {
          return
        }
        const collectionarray: BigNumber[][] = nftCollection.data as BigNumber[][]
        let ordered: OrderedNFTs = {legendary: []}
        for (var nftarray of collectionarray) {
          const item: NFT = {
            id: nftarray[0].toNumber(),
            set: nftarray[1].toNumber(),
            num: nftarray[2].toNumber()
          }
          if (item.num == 0) {
            ordered.legendary.push(item)
          } else {
            const currentSet = ordered[item.set] || {}
            const currentSetNum = currentSet[item.num] || []
            currentSetNum.push(item)
            currentSet[item.num] = currentSetNum
            ordered[item.set] = currentSet
          }
        }
        setOrderedCollection(ordered)
      }, [nftCollection.status])

    useEffect(() => {
        nftCollection.refetch()
    }, [collectionConfig])

    function readNFTCollection(config?: { args?: any, overrides?: ethers.CallOverrides | undefined }) {
        if (config)
            setCollectionConfig({...config, enabled: true})

        return nftCollection
    }

    return [orderedCollection, readNFTCollection];
}

export function useBurnableNFT(address: string | undefined, season: number | undefined) : [
    NFT[],
    (config?: {
        args?: any;
        overrides?: ethers.CallOverrides | undefined;
    } | undefined) => void] {
    const [nftCollection, readNFTCollection] = useNFTCollection(address)
    const burnable = useMemo(() => {
        if (!address || !season) {
            return []
        }
        if (!nftCollection[season]) {
          return []
        }
        const burnableNFTs: NFT[] = []
        const seasonNFTs = Object.entries(nftCollection[season])
        .filter(([n, nfts]) => parseInt(n) < 5)
        .map(([n, nfts]) => nfts.map((c) => c))

        // Draw 5 cards
        for (let i = 0; i < 5; i++) {
            if (seasonNFTs.length == 0) {
              break
            }
            // order by most common
            seasonNFTs.sort((a, b) => b.length - a.length)
            // select most common
            const selected = seasonNFTs[0].pop()
            if (selected) {
                burnableNFTs.push(selected)
            }
        }
        return burnableNFTs
      }, [nftCollection, season])

    return [burnable, readNFTCollection]
}
